import React, { useEffect } from 'react';
import FootageEditingForm from "../../components/customComponents/FootageEditingForm";
import TextToVideoForm from "../../components/customComponents/TextToVideoForm";
import { useState } from 'react';
import styles from './style.module.css';
import Layout from 'components/layout/Layout';
import { Player } from '@lottiefiles/react-lottie-player';
import { Helmet } from 'react-helmet';

export default function MainForm(props) {
  const [mainType, setMainType] = useState(props.formType == "fe" ? "multilayer" : "slideshow");
  const [subtype, setSubtype] = useState(props.formType == "fe" ? 'dhruv' : 't2vslideshow');
  const [demoVideo, setVideoDemo] = useState("dhruv");
  const [loadingState, setLoadingState] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const videoGenerationLoadingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json"
  const scriptGeneratonLoadingLottie = "https://lottie.host/dabf86d2-06d4-4dd7-b60a-75394161b83a/T0cTAu4Tyu.json"
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [loadingState])
  return (
    <>
      <Helmet>
        <title>{props.formType == "fe" ? "AI Footage Editor" : "Text to Video"}</title>
        <meta name="description" content={props.formType == "fe" ?
          "Automatically turn your raw footages into profesionally edited videos with eye-catching images, graphics, and b-rolls"
          :
          "Create stunning videos just by writing prompts or personalized content"} />
        <link rel="canonical" href={props.formType == "fe" ? "/ai-footage-editing" : "/text-to-video"} />
      </Helmet>
      <Layout headerStyle={2} footerStyle={2}>
        <div className={styles.mainContainer}>
          <div className={loadingState ? styles.loadingAnimationDiv : styles.hidden}>
            <Player className={styles.loadingAnimation}
              src={loadingType == "videogeneration" ? videoGenerationLoadingLottie : scriptGeneratonLoadingLottie}
              loop
              autoplay
            />
            {loadingType == "scriptgeneration" ?
              <h1 className={styles.LoadingText}>Crafting your masterpiece script ✍️ </h1> : null}
            {loadingType == "transcription" ? <h1 className={styles.LoadingText}>
              <br></br>
              Transcribing your video! 📼
              {/* <br/> Keep an eye on your Gmail inbox for the link to your cinematic creation under 30minutes. 📧 <br/> */}
              {/* <p> We are working to make it faster with your support and cooperation</p>  */}
            </h1> : null}
            {loadingType == "videogeneration" ?
              <h1 className={styles.LoadingText}>
                Hold tight, EditAI is creating your video. <br />
                You'll get an email with the link soon!
              </h1> : null}
          </div>

          <div className={loadingState ? styles.hidden : styles.mainContainer}>
            {props.formType == "fe" ? <FootageEditingForm setMainType={setMainType} mainType={mainType} setSubType={setSubtype} subType={subtype} setLoader={setLoadingState} setLoaderType={setLoadingType} /> : <TextToVideoForm setMainType={setMainType} mainType={mainType} setSubType={setSubtype} subType={setSubtype} setLoader={setLoadingState} setLoaderType={setLoadingType} />}
            <div className={styles.videoContainer}>
              <div className={styles.demoVideo}><p>Preview</p>
                <video className={styles.demoVideoPreview} src={`/assets/demovideos/${props.formType}/${mainType}/${subtype}.mp4`} muted autoPlay controls></video></div>
            </div>

          </div>
        </div>
      </Layout>
    </>
  )
}