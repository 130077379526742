import React from "react";
import { Link } from "react-router-dom";
import Layout from "components/layout/Layout";
import styles from "./BasePage.module.css";
import { VelocityScroll } from "components/magicui/scroll-based-velocity";
import { BorderBeam } from "components/magicui/border-beam";
import HeroVideoDialog from "components/magicui/video-dialog";
import HeroVideoDialogPortrait from "components/magicui/video-dialog/HeroVideoDialogPortrait";
import SparklesText from "components/magicui/sparkles-text";
import Faq from "components/ui/faq-accordion";
import Pricing2 from "components/layout/pricing/Pricing2";
import Counter2 from "components/layout/counter/Counter2";
import OsakaCard from "components/ui/osaka-card";
import { FaCheck } from "react-icons/fa";

const pageContent = {
    hero: {
        title: "AI Shorts Editor",
        description: "Set the trend using EditAI’s AI Shorts Editor. Upload a raw footage video in a clean background. Select audio voices, subtitle styles and layout formats. Receive link to your video on registered mail address. You can customize your video using EditAI’s user friendly interfaced Video Editor. Upload the video on social media platforms and go viral.",
        buttonText: "AI Shorts Editor",
        buttonLink: "/ai-footage-editing"
    },
    card: {
        title: "AI Shorts Editor",
        features: [
            "No manual video transcription anymore",
            "5-star transcription accuracy for all languages"
        ],
        videoSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327%2F667ef754a324d636d4c72e00_Submagic%20-%20header%202024%20-%20variante%20-%20light-transcode.mp4",
        thumbnailSrc: {
            light: "https://startup-template-sage.vercel.app/hero-light.png",
            dark: "https://startup-template-sage.vercel.app/hero-dark.png"
        }
    },
    editAI: {
        title: "Edit AI",
        videoSrc: "https://www.youtube.com/embed/qh3NGpYRG3I?si=4rb-zSdDkVK9qxxb",
        thumbnailSrc: {
            light: "https://startup-template-sage.vercel.app/hero-light.png",
            dark: "https://startup-template-sage.vercel.app/hero-dark.png"
        }
    },
    testimonials: [
        {
            companyName: "Nonagon Productions",
            website: "nonagonproductions.com",
            logoSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327/6699e3112f993d3d60948a87_Nonagon%20Logo-p-500.jpg",
            videoSrc: "https://submagic.s3.us-east-2.amazonaws.com/website/Mattin_Testimonial.mp4",
            thumbnailSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327%2F6699e0d869ba41945e418479_Mattin_Testimonial-poster-00001.jpg",
        },
        {
            companyName: "Lucid Visuals",
            website: "lucidvisuals.ca",
            logoSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327/6699e3ef3b9a9eccfac55a96_Lucid%20Visuals%20Logo.png",
            videoSrc: "https://submagic.s3.us-east-2.amazonaws.com/website/Stefan_Testimonial.mp4",
            thumbnailSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327%2F6699e19eb1eeaf4ac4ae20a9_Stefan_Testimonial-poster-00001.jpg",
        },
        {
            companyName: "Pilea Agency",
            website: "pilea.agency",
            logoSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327/6699e42c5692dd965d6e20d5_pilea_agency_logo%20(1).jpeg",
            videoSrc: "https://submagic.s3.us-east-2.amazonaws.com/website/Mattin_Testimonial.mp4",
            thumbnailSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327%2F6699e2af8e71d824814d5888_JB_Testimonial-poster-00001.jpg",
        },
        {
            companyName: "Mico Agency",
            website: "micobrand.com",
            logoSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327/6699e50aa488f72cba86e5a0_WhatsApp%20Image%202024-07-06%20at%2016.14.38%20(1)-p-500.jpeg",
            videoSrc: "https://submagic.s3.us-east-2.amazonaws.com/website/Stefan_Testimonial.mp4",
            thumbnailSrc: "https://cdn.prod.website-files.com/6469e2294ac68c3d5caea327%2F6699e45ba488f72cba8685cd_Mika_Testimonial-poster-00001.jpg",
        }
    ]
};

export default function UseCasePage() {
    return (
        <Layout headerStyle={2} footerStyle={2}>
            <div>

                {/* Hero Section */}
                <div className="grid grid-rows-[auto_1fr_auto] md:grid-rows-[1fr_1fr_auto] grid-cols-1 max-w-full">
                    <div className="hidden md:block">
                        <VelocityScroll text={`\u00A0 ${pageContent.hero.title} \u00A0`} default_velocity={3} className={`font-display text-center text-4xl font-bold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] pb-2 ${styles.pageHeading}`} />
                    </div>
                    <div className="md:hidden">
                        <SparklesText text={pageContent.hero.title} className={`font-display text-center text-5xl font-extrabold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] p-4 ${styles.pageHeading}`} />
                    </div>
                    <div className="flex justify-center items-center h-fit self-center">
                        <p className="flex text-center w-10/12 md:w-2/3 text-gray-500" dangerouslySetInnerHTML={{ __html: pageContent.hero.description }}></p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center h-fit pt-6 pb-8 md:py-0">
                        <Link to={pageContent.hero.buttonLink} className="gradient-btn gradient-btn-three wow fadeInLeft" data-wow-delay=".6s">{pageContent.hero.buttonText}</Link>
                    </div>
                </div>

                {/* Demo Section */}
                <div className="max-w-4xl mx-auto md:px-0 mt-4 md:mt-0 mb-8 md:mb-16">
                    <div className="flex flex-col space-y-4 sm:space-y-6 md:space-y-8 items-center">
                        <div className="w-full max-w-[90vw] sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-full relative">
                        <video autoPlay loop muted playsInline>
                                <source src={pageContent.card.videoSrc} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>

                {/* Edit AI Section */}
                <div className="max-w-5xl mx-auto mb-16 flex flex-col space-y-8 items-center gap-12">
                    <div className="relative flex flex-col items-center justify-center rounded-lg">
                        <SparklesText
                            text={pageContent.editAI.title}
                            className={`font-display text-center text-6xl font-extrabold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] p-4 ${styles.pageHeading}`}
                        />
                        <div className="flex items-center justify-center relative">
                            <div className="relative rounded-md">
                                <HeroVideoDialog
                                    className="dark:hidden block"
                                    animationStyle="from-center"
                                    videoSrc={pageContent.editAI.videoSrc}
                                    thumbnailSrc={pageContent.editAI.thumbnailSrc.light}
                                    thumbnailAlt="Hero Video"
                                />
                                <HeroVideoDialog
                                    className="hidden dark:block"
                                    animationStyle="from-center"
                                    videoSrc={pageContent.editAI.videoSrc}
                                    thumbnailSrc={pageContent.editAI.thumbnailSrc.dark}
                                    thumbnailAlt="Hero Video"
                                />
                                <BorderBeam size={250} duration={12} delay={9} className="hidden md:block" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonials */}
                <div className="py-16 px-4 md:px-6 lg:px-8">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-4xl md:text-5xl font-bold text-center mb-4">
                            Work like the <span className="text-primary font-extrabold border-b-4 border-primary">best agencies</span>
                        </h2>
                        <p className="text-xl text-center text-muted-foreground mb-12">
                            The best video and marketing agencies use Edit AI to speed up their workflow.
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                            {pageContent.testimonials.map((testimonial, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <div className="flex items-center mb-4">
                                        <img
                                            src={testimonial.logoSrc}
                                            alt={`${testimonial.companyName} logo`}
                                            width={40}
                                            height={40}
                                            className="rounded-full mr-3"
                                        />
                                        <div>
                                            <h3 className="font-semibold">{testimonial.companyName}</h3>
                                            <p className="text-sm text-muted-foreground">{testimonial.website}</p>
                                        </div>
                                    </div>
                                    <HeroVideoDialogPortrait
                                        className="dark:hidden block w-full"
                                        animationStyle="from-center"
                                        videoSrc={testimonial.videoSrc}
                                        thumbnailSrc={testimonial.thumbnailSrc}
                                        thumbnailAlt={`${testimonial.companyName} Testimonial`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* FAQ and Pricing Section */}
                <div id="pricing" className="area-bg !pb-8 !pt-16" data-background="../../../assets/img/bg/area_bg.jpg">
                    <Faq />
                </div>

            </div>
        </Layout>
    )
}