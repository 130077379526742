import React, { useEffect, useRef } from 'react';
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { OutlineEffect } from "three/examples/jsm/effects/OutlineEffect"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

export default function Error404Page() {
  const canvasRef = useRef();
  const loaderRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const scene = new THREE.Scene();
    const textureLoader = new THREE.TextureLoader();
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    // Base camera (Zoomed out for better visibility)
    const camera = new THREE.PerspectiveCamera(10, sizes.width / sizes.height, 0.1, 500);
    camera.position.set(8, 4, 25); // Zoomed out by increasing the Z position to 25
    scene.add(camera);

    // Controls
    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.enableZoom = true;
    controls.enablePan = true;
    controls.minDistance = 18;
    controls.maxDistance = 50;
    controls.minPolarAngle = Math.PI / 5;
    controls.maxPolarAngle = Math.PI / 2;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.outputEncoding = THREE.sRGBEncoding;

    // Materials
    const bakedTexture = textureLoader.load('https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room13/f6d2eeb487a3d1bcd9944e23621c21f60055b280/static/baked-alt.jpg');
    bakedTexture.flipY = false;
    bakedTexture.encoding = THREE.sRGBEncoding;

    const bakedMaterial = new THREE.MeshBasicMaterial({
      map: bakedTexture,
      side: THREE.DoubleSide,
      userData: {
        outlineParameters: {
          thickness: 0.003,
          color: [0, 0, 0],
          alpha: 1,
          keepAlive: true,
          visible: true,
        },
      },
    });

    // Load Model
    const loader = new GLTFLoader();
    loader.load(
      'https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room13/47b05e2db4e49eec33d63729e920894a906cb693/static/model.glb',
      (gltf) => {
        const model = gltf.scene;
        model.traverse((child) => (child.material = bakedMaterial));
        scene.add(model);
        scene.position.set(0, 0.2, 0);
        loaderRef.current.style.display = 'none'; // Hide loader after loading
      },
      (xhr) => {
        console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
      }
    );

    // Animation and Renderer
    const effect = new OutlineEffect(renderer);
    const minPan = new THREE.Vector3(-5, -2, -5);
    const maxPan = new THREE.Vector3(5, 2, 5);

    const tick = () => {
      controls.update();
      controls.target.clamp(minPan, maxPan);
      effect.render(scene, camera);
      window.requestAnimationFrame(tick);
    };

    tick();

    // Resize handler
    const onWindowResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <>
      <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0 }} />
      <div id="loader" ref={loaderRef} style={loaderStyles}>
        <h1>Loading</h1>
      </div>
      <div style={errorTextStyles}>
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you are looking for cannot be found.</p>
      </div>
      <div style={creditStyles}>
        <p>
          Model by <a href="https://codepen.io/ricardoolivaalonso" target="_blank" rel="noopener noreferrer">Ricardo Oliva Alonso</a>
        </p>
      </div>
    </>
  );
};

const loaderStyles = {
  display: 'grid',
  placeContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: '#FAEDCD',
  position: 'fixed',
  top: 0,
  left: 0,
};

const errorTextStyles = {
  position: 'absolute',
  top: '10%', // Puts the error message at the top
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
  color: '#333',
  fontFamily: 'monospace',
  background: 'rgba(250, 237, 205, 0.8)',
  padding: '20px',
  borderRadius: '8px',
};

const creditStyles = {
  position: 'absolute',
  bottom: '10px', // Places the credits at the bottom of the page
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
  fontSize: '12px',
  color: '#555',
  fontFamily: 'monospace',
};
