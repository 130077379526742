import React from "react";
import { Link } from "react-router-dom";
import Layout from "components/layout/Layout";
import { BentoCard, BentoGrid } from "components/magicui/bento-grid";
import WordPullUp from "components/magicui/word-pull-up";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { WobbleCard } from "components/ui/wobble-card";
import { Button } from "components/ui/button";
import { BellIcon, CalendarIcon, FileTextIcon, GlobeIcon, InputIcon, } from "@radix-ui/react-icons";
import { ArrowRight, Check, Lock, Shield, Users, Blend } from 'lucide-react';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { cn } from "lib/utils";

const GradientButton = ({ children, className, ...props }) => (
  <button
    className={cn(
      "px-6 py-2 rounded-full font-medium text-white bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600 hover:opacity-90 transition-opacity",
      className
    )}
    {...props}
  >
    {children}
  </button>
);

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const wrapperFramerProps = { hidden: { opacity: 0 }, show: { opacity: 1, transition: { staggerChildren: 0.5, }, }, };
const framerProps = { hidden: { y: 20, opacity: 0 }, show: { y: 0, opacity: 1 }, };

const features = [
  {
    Icon: BellIcon,
    name: "Faster Trend Acquisition",
    description: "Leverage AI to quickly tap into trending topics and create content that’s timely and relevant, keeping your brand at the forefront of industry conversations.",
    href: "/",
    cta: "Learn more",
    background: <><div className="noise-background" /><div className="bright-light-top" /></>,
    className: "lg:row-start-1 lg:row-end-4 lg:col-start-2 lg:col-end-3 bg-blue-900",
  },
  {
    Icon: CalendarIcon,
    name: "Bulk Content Production",
    description: "Efficiently produce large volumes of content at scale with minimal human intervention, allowing your team to focus on creativity and strategy.",
    href: "/",
    cta: "Learn more",
    background: <><div className="noise-background" /><div className="bright-light-top" /></>,
    className: "lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3 bg-violet-900",
  },
  {
    Icon: GlobeIcon,
    name: "No Repeated Manual Editing",
    description: "Say goodbye to repetitive editing tasks – EditAI automates the process, saving you time and effort.",
    href: "/",
    cta: "Learn more",
    background: <><div className="noise-background" /><div className="bright-light-top" /></>,
    className: "lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4 bg-orange-700",
  },
  {
    Icon: InputIcon,
    name: "No Expensive Software",
    description: "With EditAI, there’s no need to invest in `costly software or complex setups. Everything you need is integrated into a simple, user-friendly platform.",
    href: "/",
    cta: "Learn more",
    background: <><div className="noise-background" /><div className="bright-light-top" /></>,
    className: "lg:col-start-3 lg:col-end-3 lg:row-start-1 lg:row-end-2 bg-green-900",
  },
  {
    Icon: FileTextIcon,
    name: "No Outsourcing Expenses",
    description:
      "Eliminate the need for outsourcing video production and editing by automating it all in-house, reducing costs and maintaining control over your content.",
    href: "/",
    cta: "Learn more",
    background: <><div className="noise-background" /><div className="bright-light-top" /></>,
    className: "lg:col-start-3 lg:col-end-3 lg:row-start-2 lg:row-end-4 bg-rose-900",
  },
];

export default function Business() {

  const { ref: refSection2, inView: inViewSection2 } = useInView({ triggerOnce: true, threshold: 0.1, });
  const { ref: refSection3, inView: inViewSection3 } = useInView({ triggerOnce: true, threshold: 0.2, });
  const { ref: refSection4, inView: inViewSection4 } = useInView({ triggerOnce: true, threshold: 0.3, });

  return (
    <Layout headerStyle={2} footerStyle={2}>
      <div className="min-h-screen bg-white">

        {/* Section 1 */}
        <div className="container mx-auto pt-3 pb-5">
          <div className="relative">

            {/* Main content */}
            <div className="relative z-10 max-w-5xl mx-auto text-center space-y-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="space-y-4"
              >
                <motion.h1
                  variants={wrapperFramerProps}
                  initial="hidden"
                  animate="show"
                  className="text-5xl md:text-8xl font-sans font-extrabold tracking-tight flex flex-col" // Add flex properties
                >
                  {["EDIT.", "TRANSFORM.", "REIMAGINE."].map((word, i) => (
                    <motion.div
                      key={i}
                      variants={framerProps}
                      className={`${word === "TRANSFORM."
                        ? "text-[#1a0b2e]"
                        : "bg-clip-text text-transparent bg-gradient-to-r" +
                        (word === "EDIT."
                          ? " from-orange-400 via-pink-500 to-purple-600"
                          : " from-purple-600 to-pink-500")
                        }`}
                      style={{ display: "inline-block", paddingRight: "8px" }}
                    >
                      {word}
                    </motion.div>
                  ))}
                </motion.h1>


                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.5 }}
                  className="text-xl text-gray-600 max-w-2xl mx-auto"
                >
                  Your team's video production suite. <br />
                  Produce high-quality videos effortlessly.
                </motion.p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2 }}
                className="flex justify-center gap-4"
              >
                <div className="banner-btn-two">
                  <Link to="/generatevideo" className="gradient-btn gradient-btn-three wow fadeInLeft" data-wow-delay=".6s">create a video</Link>
                  <Link to="/contact" className="btn btn-three wow fadeInRight bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-700" data-wow-delay=".6s">Contact Us</Link>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <section ref={refSection2} className="container mx-auto px-4 pt-8 relative w-10/12 items-center justify-center">
          <motion.div className="w-full text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={inViewSection2 ? { opacity: 1 } : { opacity: 0 }}
              className="text-sm font-semibold text-gray-500 tracking-wider"
            >
              002
            </motion.span>
          </motion.div>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ delay: 0.2 }}
            className="text-5xl md:text-7xl font-black tracking-tight mt-4 mb-12 max-w-4xl text-center"
          >
            <span className="text-[#1a0b2e]">Professional Videos</span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600">
              8X FASTER.
            </span>
          </motion.h2>
          <motion.div className="grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-7xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.8 }}
          >
            <WobbleCard containerClassName="col-span-1 lg:col-span-2 h-full bg-blue-900 min-h-[350px] lg:min-h-[300px]">
              <div className="max-w-xs">
                <h2 className="text-left text-balance text-xl lg:text-4xl font-semibold tracking-[-0.015em] text-white">
                  Trending & Customized
                </h2>
                <img alt="automated editing" loading="lazy" decoding="async" data-nimg="1" className="w-[225px] lg:w-[350px] absolute left-3 lg:left-5 lg:bottom-32 object-contain rounded-2xl text-transparent user-drag-none user-select-none -z-10" src="assets/img/business/section-2-card-1-text.png"></img>
                <h2 className="text-left text-balance text-xl lg:text-4xl font-semibold tracking-[-0.015em] text-white mt-20">
                  Workflow
                </h2>
              </div>
              <img alt="automated editing demo" loading="lazy" decoding="async" data-nimg="1" className="lg:w-[550px] absolute -right-4 lg:-right-0 bottom-0 object-contain rounded-2xl text-transparent -z-10" src="assets/img/business/section-2-card-1.png"></img>
            </WobbleCard>
            <WobbleCard containerClassName="col-span-1 bg-violet-900 lg:min-h-[300px]">
              <h2 className="mb-4 max-w-80 text-left text-balance text-2xl md:text-xl lg:text-4xl font-semibold tracking-[-0.015em] text-white">
                Your AI Clone, <br/> Your Style, <br/> Everywhere
              </h2>
              <p className="max-w-80 text-left lg:text-base/6 text-neutral-200">
                Analyzing your style and industry trends to craft personalized captions, titles, tags, and content with automated posting across platforms.
              </p>
              <img alt="ai clone" loading="lazy" decoding="async" data-nimg="1" className="absolute left-0 bottom-0 m-auto object-contain rounded-2xl -z-10" src="assets/img/business/section-2-card-2.png"></img>
            </WobbleCard>
            <WobbleCard containerClassName="col-span-1 lg:col-span-3 bg-pink-800 min-h-[475px] lg:min-h-[600px] xl:min-h-[300px]">
              <div className="lg:pl-10 max-w-lg">
                <h2 className="max-w-full md:max-w-md text-left text-balance text-2xl lg:text-5xl font-semibold tracking-[-0.015em] text-white">
                  Content reimagined in a click!
                </h2>
                <p className="mt-4 max-w-[26rem] text-left text-base lg:text-lg text-neutral-200">
                  Remove silences from podcast, Create catchy Trailer, engaging shorts with trending editing to save ton of time and make your content everywhere
                </p>
              </div>
              <img alt="podcast editor demo" loading="lazy" decoding="async" data-nimg="1" className="h-full lg:w-3/5 absolute lg:-right-0 -bottom-36 lg:bottom-0 object-contain rounded-2xl text-transparent -z-10" src="assets/img/business/section-2-card-3.png"></img>
            </WobbleCard>
          </motion.div>
        </section>

        {/* Section 3 */}
        <section ref={refSection3} className="container mx-auto px-4 pt-8 relative w-10/12 hidden md:block">
          <motion.span
            initial={{ opacity: 0 }}
            animate={inViewSection3 ? { opacity: 1 } : { opacity: 0 }}
            className="text-sm font-semibold text-gray-500 tracking-wider"
          >
            003
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ delay: 0.2 }}
            className="text-5xl md:text-7xl font-black tracking-tight mt-4 mb-12 max-w-4xl"
          >
            <span className="text-[#1a0b2e]">A Professional Studio</span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600">
              Powered by AI.
            </span>
          </motion.h2>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.8 }}
            className="relative min-h-[600px]"
          >

            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={inViewSection3 ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
              transition={{ delay: 0.3 }}
              className="absolute left-0 top-0 w-[400px]"
            >
              <div className="relative">
                <div className="overflow-hidden rounded-3xl" style={{ clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)" }}>
                  <img
                    src="/assets/img/business/editingsoftware.jpg"
                    alt="Video editing interface"
                    width={400}
                    height={300}
                    className="w-full object-cover"
                  />
                  <div className="absolute bottom-1/4 left-4 text-xs text-white/80 font-mono">
                    Video Editor
                  </div>
                </div>
                <div className="mt-6 max-w-xs">
                  <p className="text-gray-600 leading-relaxed">
                    Perfect for teams in any industry — right from the browser.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={inViewSection3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ delay: 0.4 }}
              className="absolute right-1/3 -translate-x-1/2 top-20 w-[450px] z-10"
            >
              <div className="overflow-hidden rounded-3xl"
                style={{ clipPath: "polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 15%)" }}>
                <div className="relative aspect-square">
                  <img
                    src="/assets/img/business/kyleloftusstudios.jpg"
                    alt="AI editing features"
                    width={450}
                    height={450}
                    className="w-full h-full object-cover rounded-2xl"
                  />
                  <div className="absolute top-4 left-14 text-xs text-white/80 font-mono">
                    Footage Editing
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={inViewSection3 ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
              transition={{ delay: 0.5 }}
              className="absolute -right-8 top-40 w-1/2"
            >
              <div className="overflow-hidden rounded-3xl"
                style={{ clipPath: "polygon(0 0, 85% 0, 100% 15%, 100% 100%, 0 100%)" }}>
                <div className="relative aspect-video">
                  <img
                    src="/assets/img/business/avatar-promo-showcase.png"
                    alt="Video production workflow"
                    width={500}
                    height={300}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-4 right-20 text-xs text-slate-800 font-mono">
                    AI Avatar
                  </div>
                  <div className="absolute inset-0" />
                  <motion.div
                    className="absolute bottom-6 right-6 size-12 rounded-full border border-white/20 flex items-center justify-center"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
                  >
                    <Blend className="text-white/80" />
                  </motion.div>
                </div>
              </div>
            </motion.div>

          </motion.div>
        </section>

        {/* Section 4 */}
        <section ref={refSection4} className="container mx-auto px-4 pt-8 pb-14 relative w-10/12">
          <motion.div className="w-full text-right"
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection4 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}>
            <motion.span
              initial={{ opacity: 0 }}
              animate={inViewSection4 ? { opacity: 1 } : { opacity: 0 }}
              className="text-sm font-semibold text-gray-500 tracking-wider"
            >
              004
            </motion.span>
          </motion.div>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection4 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ delay: 0.2 }}
            className="text-6xl md:text-7xl font-black tracking-tight mt-4 mb-12 text-right"
          >
            <span className="text-[#1a0b2e]">Enterprise Grade</span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600">
              Security.
            </span>
          </motion.h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={inViewSection4 ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.8 }}
          >
            <BentoGrid className="lg:grid-rows-3">
              {features.map((feature) => (
                <BentoCard key={feature.name} {...feature} />
              ))}
            </BentoGrid>
          </motion.div>
        </section>

        {/* <div className="container mx-auto px-4 py-8 space-y-16 w-10/12">
          <motion.section
            className="grid md:grid-cols-2 gap-12 items-center"
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1, transition: { staggerChildren: 0.2 } }
            }}
            initial="initial"
            animate="animate"
          >
            <motion.div variants={fadeIn}>
              <h2 className="text-4xl font-bold mb-4 text-[#1a0b2e]">
                Create professional videos 8X faster with our Business Suite
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                Perfect for teams in any industry — right from the browser.
              </p>
              <GradientButton className="flex items-center">
                Sign Up <ArrowRight className="ml-2" />
              </GradientButton>
            </motion.div>
            <motion.div
              variants={fadeIn}
              className="bg-gradient-to-br from-purple-50 to-pink-50 p-8 rounded-2xl"
            >
              <h3 className="text-2xl font-bold mb-6 text-[#1a0b2e]">
                An end-to-end video suite for your team
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {[
                  "Marketing",
                  "Learning & Development",
                  "Internal Communication",
                  "Sales"
                ].map((item) => (
                  <motion.div
                    key={item}
                    whileHover={{ scale: 1.05 }}
                    className="bg-white rounded-lg p-4 text-center shadow-sm"
                  >
                    {item}
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </motion.section>

          <motion.section
            className="text-center"
            variants={fadeIn}
            initial="initial"
            animate="animate"
          >
            <h2 className="text-4xl font-bold mb-4 text-[#1a0b2e]">
              Here's what Edit AI can do for your team
            </h2>
            <Tabs defaultValue="editing" className="w-full">
              <TabsList className="grid w-full max-w-2xl mx-auto grid-cols-4 bg-purple-50 p-1 rounded-full">
                {["editing", "creation", "branding", "collaboration"].map((tab) => (
                  <TabsTrigger
                    key={tab}
                    value={tab}
                    className="rounded-full data-[state=active]:bg-white data-[state=active]:text-purple-600"
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </TabsTrigger>
                ))}
              </TabsList>
              <TabsContent value="editing" className="mt-8">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Card className="border-0 shadow-lg bg-gradient-to-br from-purple-50 to-pink-50">
                    <CardHeader>
                      <CardTitle className="text-2xl text-[#1a0b2e]">
                        Transform text prompts or footage into professionally edited videos
                      </CardTitle>
                      <CardDescription className="text-lg">
                        Seamlessly integrate graphics, icons, b-rolls, transitions, sound effects, and
                        background music.
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="grid sm:grid-cols-2 gap-6">
                      {[
                        "Multi-layered Editing",
                        "Graphics and Icons Integration",
                        "B-rolls and Transitions",
                        "Sound Effects and Background Music"
                      ].map((feature) => (
                        <motion.div
                          key={feature}
                          className="flex items-center gap-3 bg-white p-4 rounded-lg"
                          whileHover={{ scale: 1.02 }}
                        >
                          <Check className="text-purple-600" />
                          <span className="text-gray-700">{feature}</span>
                        </motion.div>
                      ))}
                    </CardContent>
                  </Card>
                </motion.div>
              </TabsContent>
            </Tabs>
          </motion.section>

          <motion.section
            className="bg-gradient-to-br from-purple-50 to-pink-50 p-12 rounded-2xl"
            variants={fadeIn}
            initial="initial"
            animate="animate"
          >
            <h2 className="text-4xl font-bold mb-8 text-center text-[#1a0b2e]">
              Teams love scaling video with Edit AI
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {["Whirlpool", "PandaDoc", "ConvertFlow"].map((company, index) => (
                <motion.div
                  key={company}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                >
                  <Card className="bg-white border-0 shadow-lg h-full">
                    <CardContent className="pt-6">
                      <h3 className="text-xl font-semibold mb-4 text-[#1a0b2e]">
                        {company}
                      </h3>
                      <p className="text-gray-600">
                        {company === "ConvertFlow"
                          ? "Edit AI is Canva for video editing. It just makes it so easy to edit videos like a pro."
                          : "Transform your video content with AI-powered editing tools."}
                      </p>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </div>
            <div className="text-center mt-8">
              <GradientButton>Read case study</GradientButton>
            </div>
          </motion.section>

          <motion.section variants={fadeIn} initial="initial" animate="animate">
            <h2 className="text-4xl font-bold mb-8 text-center text-[#1a0b2e]">
              Enterprise-grade security
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  title: "Data Security",
                  icon: Shield,
                  description:
                    "We practice data protection by design and comply with GDPR and the CPPA."
                },
                {
                  title: "Access Controls",
                  icon: Lock,
                  description:
                    "Secure access to private data with differentiated access rights and controls."
                },
                {
                  title: "Security Audits",
                  icon: Users,
                  description:
                    "Daily security sweeps and third-party security testing."
                },
                {
                  title: "Data Storage",
                  icon: Lock,
                  description:
                    "Enterprise-grade encryption in transit and at rest via Google Cloud Platform."
                }
              ].map((item, index) => (
                <motion.div
                  key={item.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <Card className="border-0 shadow-lg h-full bg-gradient-to-br from-purple-50 to-pink-50">
                    <CardHeader>
                      <div className="flex items-center gap-3">
                        <item.icon className="w-6 h-6 text-purple-600" />
                        <CardTitle className="text-[#1a0b2e]">{item.title}</CardTitle>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <p className="text-gray-600">{item.description}</p>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </div>
          </motion.section>
        </div> */}

      </div>
    </Layout>
  );
}