import React from 'react'
import { useState, useEffect } from 'react';
import { FileInput, Label, Checkbox } from 'flowbite-react';
import style from './style.module.css';
import LoadingBar from 'react-top-loading-bar';
import axiosInstance from 'axiosInstance';
import { useNavigate } from 'react-router-dom';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";


export default function TextToVideoForm(props) {
  const [currentWindow, setCurrentWindow] = useState(0);

  const [mode, setMode] = useState("prompt");
  const [videoOrientation, setVideoOrientaion] = useState('portrait');
  const [userInput, setUserinput] = useState();
  const [scriptStyle, setScriptStyle] = useState("formal");


  const [progress, setProgress] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate()



  const generateScript = async () => {
    setButtonDisabled(true);
    if (!userInput) {
      return alert('Fill all the fields');
    }
    props.setLoaderType("scriptgeneration");
    props.setLoader(true);

    const data = { userInput, genre: scriptStyle, mode, videoOrientation, sub_type: props.mainType };
    console.log("dataaaaa:", data)
    // const response= await axiosInstance.post('/generatescript/',JSON.stringify(data),{headers:{"Content-Type":"application/json"}});
    const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/generatescript/', JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
    if (response.data.success==0){
      alert(response.data.message);
      setProgress(100)
      props.setLoader(false);
      console.log(response);
      setButtonDisabled(false);
      return;
    }
    setProgress(100)
    props.setLoader(false);
    console.log(response);
    setButtonDisabled(false);
    navigate(`/script/${props.mainType}/${response.data.id}`)

  }

  useEffect(() => {
    const isTourCompleted = localStorage.getItem('tourCompleted');
    if(!isTourCompleted) {
    const tour = driver({
      showProgress: true,
      steps: [

        {
          element: '#slideshow',
          popover: {

            title: 'Slideshow',
            description: 'Slideshow type video consists of sequence of visually appealing images or videoclips.',
            side: 'right'
          }
        },
        {
          element: '#multilayer',
          popover: {

            title: 'Multilayer',
            description: 'Multilayer type video consists of graphics, icons and animations with sound effects.',
            side: 'left'
          }
        },
        {
          element: '#prompt',
          popover: {

            title: 'Prompt',
            description: 'Enter a Prompt to create a video',
            side: 'left'
          }
        },
        {
          element: '#content',
          popover: {

            title: 'Content',
            description: 'Alternatively, you can also enter the script of your desired video',
            side: 'right'
          }
        },
        {
          element: '#submittext',
          popover: {

            title: 'Submit',
            description: 'Proceed to next stages of your video',
            side: 'top'
          }
        },

      ]

    });

    tour.drive();
    localStorage.setItem('tourCompleted', 'true');

    // return () => {
    //   tour.done();
    // };


  }},
    [])


  useEffect(() => {

    window.scrollTo(0, 0);


  }, [currentWindow])
  return (
    <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {currentWindow == 0 ? <div className={style.container}>
        <div className="text">
          <p>Select video type</p>
        </div>
        <div className="buttons">

        <button
            id="slideshow"
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${props.mainType === 'slideshow' && videoOrientation === 'portrait'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              props.setMainType('slideshow');
              props.setSubType('t2vslideshow');
              setVideoOrientaion('portrait');
            }}
          >
            Slideshow(Portrait)
          </button>
          
          <button
            id="slideshow"
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${props.mainType === 'slideshow' && videoOrientation === 'landscape'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              props.setMainType('slideshow');
              props.setSubType('t2vslideshow');
              setVideoOrientaion('landscape');
            }}
          >
            Slideshow(Landscape)
          </button>

          <button
            id="multilayer"
            className={`inline-block rounded border px-10 py-2.5 text-sm font-medium focus:outline-none focus:ring ${props.mainType === 'multilayer'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              props.setMainType('multilayer');
              props.setSubType('t2vmultilayer');
            }}
          >
            <img className={style.beta_logo} src='/assets/img/icon/beta_icon.png'></img>
            Multilayer

          </button>

        </div>
        <br />
        <div className="text">
          <p>Select input mode</p>
        </div>
        <div className="buttons">

          <button
            id="prompt"
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${mode === 'prompt'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setMode('prompt')
            }}
          >
            Prompt
          </button>


          <button
            id="content"
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${mode === 'content'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setMode('content')

            }}
          >
            Content
          </button>
        </div>
        <br />
        <div className='videotype'>



          <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter {mode}</label>
          <textarea id="message" style={{ width: 300 }} rows="4" class="block p-2.5 w-1000 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-black" placeholder="Write your thoughts here..." onChange={(e) => setUserinput(e.target.value)}></textarea>

        </div>
        <button id="submittext" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 my-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => generateScript()} disabled={buttonDisabled}>Submit</button>
      </div> : null}

    </>
  )


}
