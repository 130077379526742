import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import axios from 'axios';
import BlogDetails from 'pages/BlogDetails';
import { Link } from 'react-router-dom';

export default function DynamicBlog() {
  const params = useParams()
  const spacing = {
    marginBottom: '20px',
    marginTop: '20px',
    padding: '20px',
    paddingRight: '40px'
  };

  const [html, setHtml] = useState("Loading..");
  const [blogDetails, setBlogDetails] = useState(null)

  const fetchData = async () => {
    try {
      const blogname = params.blog_name
      let response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/blogs/getblog/${blogname}`, { headers: { "Content-Type": "application/json" } });
      const data = response.data
      console.log(data)
      setHtml(data.blog.html_content)
      setBlogDetails(data.blog)

    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  return (
    blogDetails && <>
      <Layout headerStyle={2} footerStyle={2}>
        <section className="blog-details-area pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-details-wrap" style={spacing}>
                  <div className="blog-details-thumb">
                    <img className="m-auto" src={blogDetails.thumbnail_url} alt="" />
                    <div className="blog-details-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="blog-details-content" dangerouslySetInnerHTML={{ __html: html }} >
                  </div>
                </div>
                <div className="blog-avatar-wrap">
                  <div className="blog-post-avatar-img">
                    <img src="/assets/img/logo/3D-NO-BACK.png" alt="img" />
                  </div>
                  <div className="blog-avatar-info">
                    <h4 className="name">Team Editai</h4>
                    <p>
                      Platform to create or edit videos with the power of AI.
                    </p>
                    <div className="avatar-social">
                      <ul className="list-wrap">

                        <li>
                          <Link to="https://www.instagram.com/editai.in">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/edit-ai">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
