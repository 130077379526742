import LanguageDropdown from "./LanguageDropdown";
import { Link, useNavigate } from "react-router-dom";
import MenuMobile from "./MenuMobile";
import Cookies from "js-cookie";


const Header2 = ({ scroll, isMobileMenu, handleMobileMenu }) => {

  const refreshToken = Cookies.get('refreshToken');
  const navigate = useNavigate();
  const logout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    navigate('/')
  }
  return (
    <header>
      <div id="header-fixed-height" />
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-top-content text-center">
                <p>
                  Sign up and receive 300 Free Credits .{" "}
                  <Link to="/signup">
                    Learn more <i className="far fa-angle-right" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="menu-area menu-area-two">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img src="/assets/img/logo/logo_big.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="menu-item-has-children">
                        <Link to="/">Home</Link>
                        <ul className="sub-menu !bg-gray-100">
                          <li>
                            <Link className="text-black bg-purple-300" to="/about"
                              style={{ backgroundColor: 'transparent' }}
                              onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')}
                              onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}>
                              About us
                            </Link>
                          </li>
                          <li>
                            <Link className="text-black bg-purple-300" to="/contact"
                              style={{ backgroundColor: 'transparent' }}
                              onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')}
                              onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}>
                              Contact us
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to="/tools">AI Tools</Link>
                        <ul className="sub-menu !bg-gray-100">
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/ai-footage-editing">Footage Editing (Multilayer)</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/ai-footage-editing">Footage Editing (Slideshow)</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/text-to-video">Text to Video (Multilayer)</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/text-to-video">Text to Video (slideshow)</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/microservices/autosubtitles">Auto Subtitle Addition</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/microservices/backgroundremover">Video Background Removal</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/microservices/silenceremover">Auto Silence Removal</Link> </li>
                        </ul>
                      </li>
                      
                      <li className="menu-item-has-children">
                        <Link to="/business-suite">Suites</Link>
                        <ul className="sub-menu !bg-gray-100">
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/business-suite">Business</Link> </li>
                          <li> <Link className="text-black bg-purple-300" style={{ backgroundColor: 'transparent' }} onMouseEnter={(e) => (e.target.style.backgroundColor = '#cabffd')} onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')} to="/suites/cinematic">Cinematic</Link> </li>                        </ul>
                      </li>

                      <li>
                        <Link to="/blog">Blogs</Link>
                      </li>

                      <li>
                        <Link to="/profilepage">Profile</Link>
                      </li>
                      
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      {/* <li className="header-lang">
                        <LanguageDropdown />
                      </li> */}
                      {refreshToken ?
                        <li className="header-btn">
                          <button onClick={() => logout()} className="btn">
                            logout
                          </button>
                        </li>
                        :
                        <li className="header-btn">
                          <Link to="/login" className="btn">
                            Log in
                          </Link>
                          <Link style={{ marginLeft: "1rem" }} to="/signup" className="gradient-btn gradient-btn-three wow fadeInLeft">
                            FREE TRIAL
                          </Link>
                        </li>
                      }

                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn" onClick={handleMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo" onClick={handleMobileMenu}>
                    <Link to="/">
                      <img src="/assets/img/logo/logo_big.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                    <MenuMobile handleCloseMenu={handleMobileMenu} />
                  </div>

                  <div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li>
                        <Link to="https://twitter.com/editai_">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/editai.in">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/in/edit-ai">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://youtube.com/@_editai_">
                          <i className="fab fa-youtube" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" onClick={handleMobileMenu} />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header2;
