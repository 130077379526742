import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Play, XIcon } from "lucide-react";

import { cn } from "lib/utils";

const animationVariants = {
    "from-bottom": {
        initial: { y: "100%", opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: "100%", opacity: 0 },
    },
    "from-center": {
        initial: { scale: 0.5, opacity: 0 },
        animate: { scale: 1, opacity: 1 },
        exit: { scale: 0.5, opacity: 0 },
    },
    "from-top": {
        initial: { y: "-100%", opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: "-100%", opacity: 0 },
    },
    "from-left": {
        initial: { x: "-100%", opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: "-100%", opacity: 0 },
    },
    "from-right": {
        initial: { x: "100%", opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: "100%", opacity: 0 },
    },
    fade: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    },
    "top-in-bottom-out": {
        initial: { y: "-100%", opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: "100%", opacity: 0 },
    },
    "left-in-right-out": {
        initial: { x: "-100%", opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: "100%", opacity: 0 },
    },
};

export default function HeroVideoDialog(props) {
    const {
        animationStyle = "from-center",
        videoSrc,
        thumbnailSrc,
        thumbnailAlt = "Video thumbnail",
        className,
    } = props

    const [isVideoOpen, setIsVideoOpen] = useState(false)
    const selectedAnimation = animationVariants[animationStyle]

    return (
        <div className={cn("relative", className)}>
            <div
                className="relative cursor-pointer group"
                onClick={() => setIsVideoOpen(true)}
            >
                <img
                    src={thumbnailSrc}
                    alt={thumbnailAlt}
                    width={1920}
                    height={1080}
                    className="w-full transition-all duration-200 group-hover:brightness-[0.8] ease-out rounded-md shadow-lg border"
                />
                <div className="absolute top-2 right-2 group-hover:scale-100 scale-[0.9] transition-all duration-200 ease-out">
                    <div className="bg-primary/10 flex items-center justify-center rounded-full backdrop-blur-md size-20">
                        <div
                            className={`flex items-center justify-center bg-gradient-to-b from-primary/30 to-primary shadow-md rounded-full size-12 transition-all ease-out duration-200 relative group-hover:scale-[1.2] scale-100`}
                        >
                            <Play
                                className="size-6 text-white fill-white group-hover:scale-105 scale-100 transition-transform duration-200 ease-out"
                                style={{
                                    filter:
                                        "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {isVideoOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        onClick={() => setIsVideoOpen(false)}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-md overflow-y-auto"
                    >
                        <motion.div
                            {...selectedAnimation}
                            transition={{ type: "spring", damping: 30, stiffness: 300 }}
                            className="relative w-full max-w-sm mx-4 md:mx-0 my-8"
                        >
                            <div className="w-fit max-h-[80vh] aspect-[9/16] border-2 border-white rounded-2xl overflow-hidden isolate z-[1] relative">
                                <motion.button
                                    className="absolute top-2 right-2 text-white text-xl bg-neutral-900/50 ring-1 backdrop-blur-md rounded-full p-2 dark:bg-neutral-100/50 dark:text-black z-10"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsVideoOpen(false);
                                    }}
                                >
                                    <XIcon className="size-5" />
                                </motion.button>
                                <iframe
                                    src={videoSrc}
                                    className="w-full h-full rounded-2xl"
                                    allowFullScreen
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                ></iframe>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}