import Layout from "components/layout/Layout";
const VideoNew = () => {
  return (
    <>
      <div
        className="features-area features-bg"
        data-background="/assets/img/bg/features_bg.png"
      >
        <Layout headerStyle={2} footerStyle={2}>
          <div className="container custom-container">
            <div className="features-img">
              <video src="/assets/videos/mustansir.mp4" controls alt="" />
            </div>
          </div>
          <div className="features-shape-wrap">
            <img
              src="/assets/img/images/features_shape01.png"
              alt=""
              data-aos="fade-right"
              data-aos-delay={300}
            />
            <img
              src="/assets/img/images/features_shape02.png"
              alt=""
              className="alltuchtopdown"
            />
            <img src="/assets/img/images/features_shape03.png" alt="" />
          </div>
        </Layout>
      </div>
    </>
  );
};
export default VideoNew;
