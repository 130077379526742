import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "components/layout/Layout";
import styles from "./BasePage.module.css";
import { VelocityScroll } from "components/magicui/scroll-based-velocity";
import { BorderBeam } from "components/magicui/border-beam";
import HeroVideoDialog from "components/magicui/video-dialog";
import SparklesText from "components/magicui/sparkles-text";
import Faq from "components/ui/faq-accordion";
import Pricing2 from "components/layout/pricing/Pricing2";
import Counter2 from "components/layout/counter/Counter2";
import OsakaCard from "components/ui/osaka-card";
import { FaCheck } from "react-icons/fa";
import axios from "axios";

const demoPage = {
    hero: {
        title: "Footage Editing (Multilayer)",
        description: "Say good-bye to manual video editing. Create AI edited videos from podcast recordings or upload a raw footage. EditAI Footage Video Editor auto-edits videos instantly by adding B-rolls, eye catchy images, trendy subtitles, immersive sound effects and more.",
        buttonText: "AI Footage Editor",
        buttonLink: "/ai-footage-editing"
    },
    cards: [
        {
            title: "Subtitle styles",
            description: "With <span class=\"font-bold\">fantastic accuracy in all languages</span> (98.8%), you can now use a subtitling tool that supports and transcribes well in your language!",
            features: [
                "No manual video transcription anymore",
                "5-star transcription accuracy for all languages"
            ],
            buttonText: "Add your subtitles",
            buttonLink: "/microservices/autosubtitles",
            videoSrc: "https://www.youtube.com/embed/qh3NGpYRG3I?si=4rb-zSdDkVK9qxxb",
            thumbnailSrc: {
                light: "https://startup-template-sage.vercel.app/hero-light.png",
                dark: "https://startup-template-sage.vercel.app/hero-dark.png"
            },
            stats: {
                number: "25+",
                text: "Element Patterns"
            },
            flags: [
                { src: "https://cdn-icons-png.flaticon.com/512/555/555526.png", alt: "USA Flag" },
                { src: "https://cdn-icons-png.flaticon.com/512/197/197571.png", alt: "Germany Flag" },
                { src: "https://cdn-icons-png.flaticon.com/512/197/197560.png", alt: "France Flag" },
                { src: "https://cdn-icons-png.flaticon.com/512/330/330591.png", alt: "Korea Flag" }
            ]
        },
        {
            title: "Use the hottest subtitle designs, created by top creators",
            description: "Famous content creators and top video editors collaborate with us to create the most engaging subtitle templates that are proven to boost video engagement. 🔥",
            features: [
                "20+ subtitle styles that increase your views",
                "Add emojis, highlight keywords with GIFs and images",
                "Subtitle templates used by top creators"
            ],
            buttonText: "Add subtitles now",
            buttonLink: "/microservices/autosubtitles",
            imageSrc: "https://assets-static.invideo.io/images/large/Frame_1214132857_ab4564030f.webp"
        },
        {
            title: "Bring contextual elements with visual hooks",
            description: "Subtitles are part of a visual hook. They let your viewers focus on dynamic elements and increase the retention rate of your video. <br /><br />A 2-second hook title will engage your audience fully with contextual and visual elements at the beginning of your video! 🧡",
            features: [
                "Auto-generation of the text (AI)",
                "Customize your hook title in 1 click"
            ],
            buttonText: "Add my 2-second hook",
            buttonLink: "/microservices/autosubtitles",
            imageSrc: "https://assets-static.invideo.io/images/large/Frame_1214133755_a62e22fb82.webp"
        }
    ],
    editAI: {
        title: "Edit AI",
        videoSrc: "https://www.youtube.com/embed/qh3NGpYRG3I?si=4rb-zSdDkVK9qxxb",
        thumbnailSrc: {
            light: "https://startup-template-sage.vercel.app/hero-light.png",
            dark: "https://startup-template-sage.vercel.app/hero-dark.png"
        }
    }
};

export default function DynamicBasePage() {

    const params=useParams();
    const [pageContent, setpageContent] = useState(demoPage);

    

    

    const fetchBasePage=async()=>{
        try{
          const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/getbasepage/${params.url}`);
        console.log(response);
        console.log(response.data);
        const data=response.data.page;

        const page={
            hero: {
                title: data.title ,
                description: data.description,
                buttonText: data.buttonText,
                buttonLink: data.buttonLink
            },
            cards: data.cards,
            editAI: {
                title: "Edit AI",
                videoSrc: "https://www.youtube.com/embed/qh3NGpYRG3I?si=4rb-zSdDkVK9qxxb",
                thumbnailSrc: {
                    light: "https://startup-template-sage.vercel.app/hero-light.png",
                    dark: "https://startup-template-sage.vercel.app/hero-dark.png"
                }
            },
            faqs:data.faqs
        }
        console.log("Page content")
        console.log(page);
        setpageContent(page);
        }
        catch(e){
          console.log(e);
        }
      }
    
      useEffect(()=>{
        fetchBasePage();
      },[])


    return (
        <Layout headerStyle={2} footerStyle={2}>
            <div>

                {/* Hero Section */}
                <div className="grid grid-rows-[auto_1fr_auto] md:grid-rows-3 grid-cols-1 max-w-full">
                    <div className="hidden md:block">
                        <VelocityScroll text={`\u00A0 ${pageContent.hero.title} \u00A0`} default_velocity={3} className={`font-display text-center text-4xl font-bold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] pb-2 ${styles.pageHeading}`} />
                    </div>
                    <div className="md:hidden">
                        <SparklesText text={pageContent.hero.title} className={`font-display text-center text-5xl font-extrabold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] p-4 ${styles.pageHeading}`} />
                    </div>
                    <div className="flex justify-center items-center h-fit self-center">
                        <p className="flex text-center w-10/12 md:w-2/3 text-gray-500" dangerouslySetInnerHTML={{ __html: pageContent.hero.description }}></p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center h-fit pt-6 pb-8 md:py-0">
                        <Link to={pageContent.hero.buttonLink} className="gradient-btn gradient-btn-three wow fadeInLeft" data-wow-delay=".6s">{pageContent.hero.buttonText}</Link>
                    </div>
                </div>

                {/* Alternate Card Design */}
                {/* <div>
                    <OsakaCard>
                        <div className={styles.card}>
                            <div className={styles.additional}>
                                <div className={styles.userCard}>
                                    <div className="text-center text-base">
                                        jon doe
                                    </div>
                                </div>
                                <div className={styles.moreInfo}>
                                    <div className="p-4 text-center text-2xl">
                                        lorem ipsum dolor sit amet
                                    </div>
                                </div>
                            </div>
                            <div className={`p-4 ${styles.general}`}>
                                <div className="text-center text-lg">
                                    basic info about jon doe
                                </div>
                                <div className="text-center text-lg">
                                    Mouse over the card for more info
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.card} ${styles.green}`}>
                            <div className={styles.additional}>
                                <div className={styles.userCard}>
                                    <div className="text-center text-base">
                                        jon doe
                                    </div>
                                </div>
                                <div className={styles.moreInfo}>
                                    <div className="p-4 text-center text-2xl">
                                        lorem ipsum dolor sit amet
                                    </div>
                                </div>
                            </div>
                            <div className={`p-4 ${styles.general}`}>
                                <div className="text-center text-lg">
                                    basic info about jon doe
                                </div>
                                <div className="text-center text-lg">
                                    Mouse over the card for more info
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.additional}>
                                <div className={styles.userCard}>
                                    <div className="text-center text-base">
                                        jon doe
                                    </div>
                                </div>
                                <div className={styles.moreInfo}>
                                    <div className="p-4 text-center text-2xl">
                                        lorem ipsum dolor sit amet
                                    </div>
                                </div>
                            </div>
                            <div className={`p-4 ${styles.general}`}>
                                <div className="text-center text-lg">
                                    basic info about jon doe
                                </div>
                                <div className="text-center text-lg">
                                    Mouse over the card for more info
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.card} ${styles.green}`}>
                            <div className={styles.additional}>
                                <div className={styles.userCard}>
                                    <div className="text-center text-base">
                                        jon doe
                                    </div>
                                </div>
                                <div className={styles.moreInfo}>
                                    <div className="p-4 text-center text-2xl">
                                        lorem ipsum dolor sit amet
                                    </div>
                                </div>
                            </div>
                            <div className={`p-4 ${styles.general}`}>
                                <div className="text-center text-lg">
                                    basic info about jon doe
                                </div>
                                <div className="text-center text-lg">
                                    Mouse over the card for more info
                                </div>
                            </div>
                        </div>
                    </OsakaCard>
                </div> */}

                {/* Cards Section */}
                <div className="max-w-5xl mx-auto mb-16 flex flex-col space-y-8 items-center gap-12">
                    {pageContent.cards && pageContent.cards.map((card, index) => (
                        <div key={index} className="flex flex-col md:flex-row gap-8 bg-white rounded-lg p-6 md:p-8 md:py-10 shadow-lg">
                            <div className="flex flex-col space-y-4 md:w-1/2">
                                <h2 className="text-2xl md:text-3xl font-bold text-left">
                                    {card.title}
                                </h2>
                                <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: card.description }}></p>
                                <ul className="space-y-2">
                                    {card.features && card.features.map((feature, fIndex) => (
                                        <li key={fIndex} className="flex items-center space-x-2">
                                            <FaCheck className="text-green-500" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                <a href={card.buttonLink} className="bg-indigo-600 text-white py-2 px-4 rounded-lg font-semibold text-center w-fit">
                                    {card.buttonText}
                                </a>
                            </div>
                            <div className="flex items-center justify-center relative md:w-1/2 mt-6 md:mt-0">
                                {card.videoSrc ? (
                                    <div className="relative w-full">
                                        <HeroVideoDialog
                                            className="dark:hidden block"
                                            animationStyle="from-center"
                                            videoSrc={card.videoSrc}
                                            thumbnailSrc={card.thumbnailSrc.light}
                                            thumbnailAlt="Hero Video"
                                        />
                                        <HeroVideoDialog
                                            className="hidden dark:block"
                                            animationStyle="from-center"
                                            videoSrc={card.videoSrc}
                                            thumbnailSrc={card.thumbnailSrc.dark}
                                            thumbnailAlt="Hero Video"
                                        />
                                        {card.stats && (
                                            <div className="absolute top-2 right-2 md:top-4 md:right-4 bg-white p-2 md:p-4 rounded-lg shadow-lg flex items-center space-x-2">
                                                <span className="text-lg md:text-2xl font-bold">{card.stats.number}</span>
                                                <div className="flex items-center space-x-1 text-xs md:text-sm">
                                                    <span>{card.stats.text}</span>
                                                    <span role="img" aria-label="thumbs-up">👍</span>
                                                </div>
                                            </div>
                                        )}
                                        {card.flags && (
                                            <div className="absolute bottom-2 left-2 md:bottom-4 md:left-4 bg-white p-2 md:p-4 rounded-lg shadow-lg grid grid-cols-4 gap-1 md:gap-2">
                                                {card.flags && card.flags.map((flag, fIndex) => (
                                                    <img key={fIndex} src={flag.src} alt={flag.alt} className="w-4 md:w-8" />
                                                ))}
                                                <div className="col-span-4 text-xs md:text-sm text-gray-500">& many more</div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <img
                                        src={card.imageSrc}
                                        alt={card.title}
                                        className="w-full rounded-lg object-cover"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                
                {/* Edit AI Section */}
                <div className="max-w-5xl mx-auto mb-16 flex flex-col space-y-8 items-center gap-12">
                    <div className="relative flex flex-col items-center justify-center rounded-lg">
                        <SparklesText
                            text={pageContent.editAI.title}
                            className={`font-display text-center text-6xl font-extrabold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] p-4 ${styles.pageHeading}`}
                        />
                        <div className="flex items-center justify-center relative">
                            <div className="relative rounded-md">
                                <HeroVideoDialog
                                    className="dark:hidden block"
                                    animationStyle="from-center"
                                    videoSrc={pageContent.editAI.videoSrc}
                                    thumbnailSrc={pageContent.editAI.thumbnailSrc.light}
                                    thumbnailAlt="Hero Video"
                                />
                                <HeroVideoDialog
                                    className="hidden dark:block"
                                    animationStyle="from-center"
                                    videoSrc={pageContent.editAI.videoSrc}
                                    thumbnailSrc={pageContent.editAI.thumbnailSrc.dark}
                                    thumbnailAlt="Hero Video"
                                />
                                    <BorderBeam size={250} duration={12} delay={9} className="hidden md:block"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* FAQ and Pricing Section */}
                <div id="pricing" className="area-bg !pb-8 !pt-16" data-background="../../../assets/img/bg/area_bg.jpg">
                    <Faq faqs={ pageContent && pageContent.faqs}/>
                    <Pricing2 />
                    {/* <Counter2/> */}
                </div>

            </div>
        </Layout>
    )
}