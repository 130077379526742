import React, { useState, useEffect, useRef } from "react";
import Layout from "components/layout/Layout";
import styles from './style.module.css';
import { ChromePicker } from "react-color";
import { FileInput, Label, Button } from 'flowbite-react';

const VideoPreview = ({ src }) => {
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play(); // Start playing the video
      videoRef.current.controls = true; // Show controls
      videoRef.current.muted = false; // Unmute the video
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
      videoRef.current.controls = false; // Hide controls
      videoRef.current.muted = true; // Mute the video again
    }
  };

  return (
    <video
      ref={videoRef}
      className={`${styles.demoVideoPreview} h-auto max-w-full rounded-lg`}
      src={src}
      muted
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

const ColorPicker = ({ color, onColorChange }) => {
  const [displayPicker, setDisplayPicker] = useState(false);
  const [colorValue, setColorValue] = useState(color || '#000000');
  const colorPickerRef = useRef(null);

  const updateColors = (color) => {
    if (color.startsWith('#')) {
      setColorValue(color);
    } else if (color.startsWith('rgba')) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
      const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
      setColorValue(hex);
    }
  };

  const handleInputChange = (e) => {
    setColorValue(e.target.value);
    updateColors(e.target.value);
    onColorChange(e.target.value);
  };

  const handlePickerChange = (color) => {
    const rgbaString = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    setColorValue(color.rgb.a === 1 ? color.hex : rgbaString);
    onColorChange(colorValue);
  };

  const togglePicker = () => setDisplayPicker(!displayPicker);

  const handleDocumentClick = (e) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
      setDisplayPicker(false);
    }
  };

  useEffect(() => {
    if (displayPicker) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [displayPicker]);

  return (
    <div className="input-group color-picker" ref={colorPickerRef}>
      <input type="text" className="form-control border border-slate-600 rounded-full" value={colorValue} onFocus={togglePicker} onChange={handleInputChange} />
      <span className="input-group-addon color-picker-container">
        <span
          className=""
          style={{ backgroundColor: colorValue }}
          onClick={togglePicker}
        ></span>
        {displayPicker && (
          <div className="picker-popover">
            <ChromePicker color={colorValue} onChange={handlePickerChange} />
          </div>
        )}
      </span>
    </div>
  );
};

function TrendySubtitles() {

  const [loadingState, setLoadingState] = useState(false);
  const [defaultColor, setDefaultColor] = useState("#000000");
  const [fontSize, setfontSize] = useState("large");
  const [subtitlePosition, setsubtitlePosition] = useState("above");
  const [video, setVideo] = useState();
  const [formData, setFormData] = useState({
    font: "Arial",
    colorPriority1: "#000000",
    colorPriority2: "#000000",
    colorPriority3: "#000000",
    colorPriority4: "#000000",
    size: "medium",
    position: "above center",
    video: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "video") {
      setFormData({
        ...formData,
        video: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    setLoadingState(true);
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
  };

  return (
    <Layout headerStyle={2} footerStyle={2}>

      {/* Old forms */}
      {/* <form onSubmit={handleSubmit}>

        <div>
          <label>Font: </label>
          <select
            name="font"
            value={formData.font}
            onChange={handleChange}
          >
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
            <option value="Georgia">Georgia</option>
            <option value="Verdana">Verdana</option>
          </select>
        </div>

        <div>
          <label>Color Priority 1: </label>
          <input
            type="color"
            name="colorPriority1"
            value={formData.colorPriority1}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Color Priority 2: </label>
          <input
            type="color"
            name="colorPriority2"
            value={formData.colorPriority2}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Color Priority 3: </label>
          <input
            type="color"
            name="colorPriority3"
            value={formData.colorPriority3}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Color Priority 4: </label>
          <input
            type="color"
            name="colorPriority4"
            value={formData.colorPriority4}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Size: </label>
          <select
            name="size"
            value={formData.size}
            onChange={handleChange}
          >
            <option value="medium">Medium</option>
            <option value="big">Big</option>
          </select>
        </div>

        <div>
          <label>Position: </label>
          <select
            name="position"
            value={formData.position}
            onChange={handleChange}
          >
            <option value="above center">Above Center</option>
            <option value="below center">Below Center</option>
          </select>
        </div>

        <div>
          <label>Upload Video: </label>
          <input
            type="file"
            name="video"
            accept="video/*"
            onChange={handleChange}
          />
        </div>

        <button type="submit">Submit</button>
      </form> */}

      <div className={styles.mainContainer}>

        <div className={styles.container}>

          {/* Fonts */}
          <label class="block mb-2 text-base font-medium text-slate-600 dark:text-white mt-3">Fonts</label>
          <select name="font" value={formData.font} onChange={handleChange} id="countries" class="max-w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="Arial" selected>Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
            <option value="Georgia">Georgia</option>
            <option value="Verdana">Verdana</option>
          </select>

          {/* Colour Picker */}
          <label class="block mb-2 text-base font-medium text-slate-600 dark:text-white mt-3">Colour Priority</label>
          <div className="flex gap-2">
            <div className="max-w-28">
              <ColorPicker color={defaultColor} onColorChange={setDefaultColor} />
              <div className="font-mono text-base text-slate-400 text-center"> 1 </div>
            </div>
            <div className="max-w-28">
              <ColorPicker color={defaultColor} onColorChange={setDefaultColor} />
              <div className="font-mono text-base text-slate-400 text-center"> 2 </div>
            </div>
            <div className="max-w-28">
              <ColorPicker color={defaultColor} onColorChange={setDefaultColor} />
              <div className="font-mono text-base text-slate-400 text-center"> 3 </div>
            </div>
            <div className="max-w-28">
              <ColorPicker color={defaultColor} onColorChange={setDefaultColor} />
              <div className="font-mono text-base text-slate-400 text-center"> 4 </div>
            </div>
          </div>

          {/* Font Size */}
          <label class="block mb-2 text-base font-medium text-slate-600 dark:text-white mt-3">Font Size</label>
          <div className="buttons">
            <button
              id="Large"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${fontSize === 'large'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => { }}
            >
              Large
            </button>

            <button
              id="Small"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${fontSize === 'small'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => { }}
            >
              Small
            </button>

          </div>

          {/* Subtitle Position */}
          <label class="block mb-2 text-base font-medium text-slate-600 dark:text-white mt-3">Subtitle Position</label>
          <div className="buttons">
            <button
              id="above"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitlePosition === 'above'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => { }}
            >
              Above Center
            </button>

            <button
              id="below"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitlePosition === 'below'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => { }}
            >
              Below Center
            </button>

          </div>

          {/* Video Uploader */}
          <label class="block mb-2 text-base font-medium text-slate-600 dark:text-white mt-3">Upload Video</label>
          <div className="flex w-full items-center justify-center my-2">
            <Label
              htmlFor="dropzone-file"
              className="dark:hover:bg-bray-800 flex h-64  cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pb-6 pt-5 pr-20 pl-20">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLineJoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {video ? <span className="font-semibold">{video.name}</span> : <span className="font-semibold">Click to upload</span>}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">.mp4 format only</p>
              </div>
              <FileInput id="dropzone-file" className="hidden" onChange={(e) => { setVideo(e.target.files[0]) }} />
            </Label>
          </div>

          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-3 mt-4 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={handleSubmit}>Submit</button>

        </div>

        <div className={`${styles.videoContainer}`}>
          <div className={`${styles.demoVideo}`}><p>Preview</p>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-3">
              <div className="grid gap-3">
                <VideoPreview src="/assets/demovideos/t2v/slideshow/t2vslideshow.mp4" />
                <VideoPreview src="/assets/demovideos/subtitles/NOAH.mp4" />
              </div>
              <div className="grid gap-3">
                <VideoPreview src="/assets/demovideos/subtitles/NOAH.mp4" />
                <VideoPreview src="/assets/demovideos/t2v/slideshow/t2vslideshow.mp4" />
              </div>
            </div>
          </div>
        </div>

      </div>

    </Layout>
  );
}

export default TrendySubtitles;