import { useState } from 'react'
import { Minus, Plus } from 'lucide-react'

const demoFaqs = [
    {
        question: "What's the best thing about Switzerland?",
        answer: "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat."
    },
    {
        question: "How do you make holy water?",
        answer: "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam aut tempora vitae odio inventore fuga aliquam nostrum quod porro. Delectus quia facere id sequi expedita natus."
    },
    {
        question: "What do you call someone with no body and no nose?",
        answer: "Nobody knows."
    },
    {
        question: "Why do you never see elephants hiding in trees?",
        answer: "Because they're so good at it."
    },
    {
        question: "Why can't you hear a pterodactyl go to the bathroom?",
        answer: "Because the 'P' is silent."
    },
    {
        question: "Why did the invisible man turn down the job offer?",
        answer: "He couldn't see himself doing it."
    }
]

export default function Faq(props) {
    const [openIndex, setOpenIndex] = useState(null)

    const faqs = props.faqs || demoFaqs

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    return (
        <div className="max-w-5xl mx-auto p-4 mb-16 flex flex-col gap-8">
            <h2 className="mb-8 text-5xl font-extrabold text-center">FAQs</h2>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border-b border-gray-200 pb-4">
                        <button
                            className="flex justify-between items-center w-full text-left"
                            onClick={() => toggleFAQ(index)}
                        >
                            <span className="text-lg font-medium text-gray-800">{faq.question}</span>
                            {openIndex === index ? (
                                <Minus className="h-5 w-5 text-gray-500" />
                            ) : (
                                <Plus className="h-5 w-5 text-gray-500" />
                            )}
                        </button>
                        {openIndex === index && (
                            <p className="mt-2 text-gray-600">{faq.answer}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}