import React from 'react'
import Layout from 'components/layout/Layout';
import Services2 from 'components/layout/services/Services2';
import { Helmet } from 'react-helmet';
export default function AITools() {
  return (
    <div>
      <Helmet>
        <title>Edit AI tools</title>
        <meta name="description" content="Explore EditAI's powerful tools: Text to video, AI footage editor, automated subtitles, video background remover for free!" />
        <meta name="canonical" link="/tools" />
      </Helmet>
      <Layout headerStyle={2} footerStyle={2}>
      <Services2 />
      </Layout>
    </div>
  )
}
