import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Collapse } from "react-collapse";
import Cookies from "js-cookie";

function MenuMobile({ handleCloseMenu }) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });

  const navigate = useNavigate();
  const logout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    navigate('/');
    handleCloseMenu();
  };

  const refreshToken = Cookies.get('refreshToken');

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({ key: "", status: false });
    } else {
      setToggle({ key, status: true });
    }
  };

  return (
    <ul className="navigation">
      {/* HOME */}
      <li className="menu-item-has-children">
        <h5
          onClick={() => {
            handleToggle("home");
          }}
        >
          <Link to="/" onClick={handleCloseMenu}>Home</Link>
        </h5>
        <Collapse isOpened={toggle.key === "home"}>
          <ul
            className="sub-menu !bg-gray-100"
            style={{
              display: `${toggle.key === "home" ? "block" : "none"}`,
            }}
          >
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/about">About us</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/contact">Contact us</Link>
            </li>
          </ul>
        </Collapse>
        <div
          className="dropdown-btn"
          onClick={() => {
            handleToggle("home");
          }}
        >
          <span className="fas fa-angle-down" />
        </div>
      </li>

      {/* AI TOOLS */}
      <li className="menu-item-has-children">
        <h5
          onClick={() => {
            handleToggle("tools");
          }}
        >
          <Link to="/tools" onClick={handleCloseMenu}>AI Tools</Link>
        </h5>
        <Collapse isOpened={toggle.key === "tools"}>
          <ul
            className="sub-menu !bg-gray-100"
            style={{
              display: `${toggle.key === "tools" ? "block" : "none"}`,
            }}
          >
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/ai-footage-editing">Footage Editing (Multilayer)</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/ai-footage-editing">Footage Editing (Slideshow)</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/text-to-video">Text to Video (Multilayer)</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/text-to-video">Text to Video (Slideshow)</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/microservices/autosubtitles">Auto Subtitle Addition</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/microservices/backgroundremover">Video Background Removal</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/microservices/silenceremover">Auto Silence Removal</Link>
            </li>
          </ul>
        </Collapse>
        <div
          className="dropdown-btn"
          onClick={() => {
            handleToggle("tools");
          }}
        >
          <span className="fas fa-angle-down" />
        </div>
      </li>

      {/* Suites */}
      <li className="menu-item-has-children">
        <h5
          onClick={() => {
            handleToggle("suites");
          }}
        >
          <Link to="/business-suite" onClick={handleCloseMenu}>Suites</Link>
        </h5>
        <Collapse isOpened={toggle.key === "suites"}>
          <ul
            className="sub-menu !bg-gray-100"
            style={{
              display: `${toggle.key === "suites" ? "block" : "none"}`,
            }}
          >
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/business-suite">Business</Link>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <Link to="/suites/cinematic">Cinematic</Link>
            </li>
          </ul>
        </Collapse>
        <div
          className="dropdown-btn"
          onClick={() => {
            handleToggle("suites");
          }}
        >
          <span className="fas fa-angle-down" />
        </div>
      </li>

      {/* BLOGS */}
      <li
        onClick={() => handleCloseMenu()}
      >
        <Link to="/blog">Blogs</Link>
      </li>

      {/* PROFILE */}
      <li
        onClick={() => handleCloseMenu()}
      >
        <Link to="/profilepage">Profile</Link>
      </li>

      {/* LOGIN / LOGOUT */}
      {refreshToken ? (
        <li>
          <Link onClick={() => logout()}>Log Out</Link>
        </li>
      ) : (
        <li
          onClick={() => handleCloseMenu()}
        >
          <Link to="/login">Log in</Link>
        </li>
      )}
    </ul>
  );
}

export default MenuMobile;
