import React, { useState } from 'react';
import Gallery1 from 'pages/Gallery1';
import Gallery2 from 'pages/Gallery2';
import styles from "./style.module.css";
import Layout from 'components/layout/Layout';
import { Popover } from "flowbite-react";
import { Helmet } from 'react-helmet';

export default function Index() {
  const [activeTab, setActiveTab] = useState('footageEditor');

  const renderContent = () => {
    if (activeTab === 'textToVideo') {
      return <div className={styles.tv}><Gallery1 /></div>;
    } else if (activeTab === 'footageEditor') {
      return <div className={styles.fe}><Gallery2 /></div>;
    }
  };

  return (
    <>
      <Helmet>
        <title>Generate Video</title>
        <meta name="description" content="All-in-one AI Automated Advanced Video editing and text to video tool.
                  with transitions, captions/subtitles and Sound effects (SFX)"/>
        <link rel="canonical" href="/generatevideo" />
      </Helmet>
      <Layout headerStyle={2} footerStyle={2}>
        <div className={styles.container}>
          <div className="border-b border-gray-200 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">

              <li className="me-2">
                <Popover className='z-20 bg-blue-50 rounded-lg'
                  trigger="hover"
                  content={
                    <div className="w-96 text-sm text-gray-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
                      <div className="grid grid-cols-5">
                        <div className="col-span-5 p-3">
                          <div className="space-y-2 text-justify">
                            <h3 className="font-semibold text-gray-900 dark:text-white"><b>Transform raw clips into AI edited video</b></h3>

                            <p class="max-w-md space-y-1 text-sm font-bold text-gray-500 list-disc list-inside dark:text-gray-400">
                              Automatically enhance your raw footage with
                              <ul class="max-w-md space-y-1 font-normal text-gray-500 list-inside dark:text-gray-400">
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Eye-catching Images
                                </li>
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Graphics
                                </li>
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  B-Rolls
                                </li>
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Custom Icons
                                </li>
                              </ul>
                            </p>

                            <p class="max-w-md space-y-1 text-sm font-bold text-gray-500 list-disc list-inside dark:text-gray-400">
                              Effortlessly integrate
                              <ul class="max-w-md space-y-1 font-normal text-gray-500 list-inside dark:text-gray-400">
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Trendy Transitions
                                </li>
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Zoom Effects
                                </li>
                                <li class="flex items-center">
                                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                  </svg>
                                  Immersive Sound Effects
                                </li>
                              </ul>
                            </p>

                            <p class="max-w-md space-y-1 text-sm font-bold text-gray-500 list-disc list-inside dark:text-gray-400">
                              Generate automatic subtitles to enhance accessibility and engagement.
                            </p>

                            {/* <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                            <li>
                              Generate automatic subtitles to enhance accessibility and engagement.
                            </li>
                          </ul> */}

                            {/* <p className='tracking-tight'>
                            Automatically enhance your raw footage with eye-catching images, graphics, and b-rolls. 
                            Effortlessly integrate trendy transitions and zoom effects, and immersive sound effects (SFX). 
                            Generate automatic subtitles to enhance accessibility and engagement.
                          </p> */}
                            <a
                              href="#"
                              className="flex items-center font-medium text-blue-600 hover:text-blue-700 hover:underline dark:text-blue-500 dark:hover:text-blue-600"
                            >
                              Read more{' '}
                              <svg
                                className="ms-1.5 h-2 w-2 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <video className="col-span-5 h-full" src="https://assets-editai.azureedge.net/videos/1716219192_Fe_Wqruc6_bqs88h.mp4" alt="footage editing" loop autoPlay muted />
                      </div>
                    </div>
                  }
                >
                  <a
                    href="#"
                    onClick={() => setActiveTab('footageEditor')}
                    className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'footageEditor' ? 'border-blue-600 text-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'} rounded-t-lg`}
                    aria-current={activeTab === 'footageEditor' ? 'page' : undefined}
                  >
                    <svg className={`w-4 h-4 me-2 ${activeTab === 'textToVideo' ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                      <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                    </svg>Footage editor
                  </a>
                </Popover>
              </li>

              <li className="me-2">
                <Popover className='z-20 bg-blue-50 rounded-lg'
                  trigger="hover"
                  content={
                    <div className="w-96 text-sm text-gray-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
                      <div className="grid grid-cols-5">
                        <div className="col-span-5 p-3">
                          <div className="space-y-2 text-justify">
                            <h3 className="font-semibold text-gray-900 dark:text-white"><b>Create a video with a single prompt</b></h3>
                            {/* <p>
                          Automatically enhance your prompt or personalized content with eye-catching images, graphics, and b-rolls.
                          Effortlessly integrate trendy transitions and zoom effects, and immersive sound effects (SFX) with Human Intonated Speech.Generate automatic subtitles to enhance accessibility and engagement.
                        </p> */}

                            <ul class="max-w-md space-y-1 font-normal text-gray-500 list-inside dark:text-gray-400">
                              <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Eye-catching images, graphics, and b-rolls.
                              </li>
                              <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Trendy transitions, zoom effects and sound effects.
                              </li>
                              <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Cool subtitles.
                              </li>
                            </ul>

                            <a
                              href="#"
                              className="flex items-center font-medium text-blue-600 hover:text-blue-700 hover:underline dark:text-blue-500 dark:hover:text-blue-600"
                            >
                              Read more{' '}
                              <svg
                                className="ms-1.5 h-2 w-2 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <video className="col-span-5 h-full" src="https://assets-editai.azureedge.net/videos/1716219598_T2v_Multi_Dgkeuz_lufbj0.mp4" alt="text to video multilayer" loop autoPlay muted />
                        {/* <img
                          src="https://flowbite.com/docs/images/popovers/italy.png"
                          className="col-span-6 h-full"
                          alt="Italy map"
                        /> */}
                      </div>
                    </div>
                  }
                >
                  <a
                    href="#"
                    onClick={() => setActiveTab('textToVideo')}
                    className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'textToVideo' ? 'border-blue-600 text-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'} rounded-t-lg`}
                    aria-current={activeTab === 'textToVideo' ? 'page' : undefined}
                  >
                    <svg className={`w-4 h-4 me-2 ${activeTab === 'footageEditor' ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                    </svg>Text to video
                  </a>
                </Popover>
              </li>

            </ul>
          </div>

          {renderContent()}
        </div>
      </Layout>
    </>
  );
}
