import Layout from "components/layout/Layout";
import DataBg from "components/layout/DataBg";
import axios from "axios";
import { data } from "autoprefixer";
import { useState, useEffect } from "react";
import { FileInput, Label, Checkbox, Button, Spinner } from 'flowbite-react';

export default function Signup() {

  const [email, setEmail] = useState();
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [password, setPassword] = useState();
  const [re_password, setRePassword] = useState();
  const [view, setView] = useState(1)
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // IP tracker
  const loadIpTracker = () => {
    const stored = localStorage.getItem('ipTracker');
    return stored ? new Map(JSON.parse(stored)) : new Map();
  };
  const saveIpTracker = (tracker) => {
    localStorage.setItem('ipTracker', JSON.stringify(Array.from(tracker.entries())));
  };
  let ipTracker = loadIpTracker();

  const signup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    // Whitelist of genuine email providers
    const whitelistedDomains = [
      'gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com',
      'icloud.com', 'protonmail.com', 'mail.com', 'zoho.com', 'yandex.com',
      'gmx.com', 'tutanota.com', 'fastmail.com', 'hushmail.com', 'mailbox.org',
      'runbox.com', 'posteo.de', 'kolabnow.com', 'startmail.com', 'tutamail.com',
      // Add more as needed
    ];

    const isWhitelisted = (email) => {
      const domain = email.split('@')[1].toLowerCase();
      return whitelistedDomains.includes(domain);
    };

    const verifyEmail = async (email) => {
      const apiKey = process.env.REACT_APP_ABSTRACT_API_KEY;
      const url = `https://emailvalidation.abstractapi.com/v1/?api_key=${apiKey}&email=${email}`;

      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error('Error verifying email:', error);
        return null;
      }
    };

    // Get user's IP address
    const getUserIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
      } catch (error) {
        console.error('Error getting IP:', error);
        return null;
      }
    };

    // Check and update rate limit
    const checkRateLimit = (ip) => {
      const now = Date.now();
      const hourAgo = now - 60 * 60 * 1000; // 1 hour ago

      if (!ipTracker.has(ip)) {
        ipTracker.set(ip, [now]);
      } else {
        const requests = ipTracker.get(ip).filter(time => time > hourAgo);
        requests.push(now);
        ipTracker.set(ip, requests);
      }

      saveIpTracker(ipTracker); // Save updated tracker to localStorage
      console.log('IP Tracker ', ipTracker);
      return ipTracker.get(ip).length <= 5; // Allow 5 requests per hour
    };

    try {
      const userIP = await getUserIP();

      if (!userIP) {
        alert('Unable to verify your network. Please try again later.');
        setButtonDisabled(false);
        return;
      }

      if (!checkRateLimit(userIP)) {
        alert('Too many registration attempts. Please try again later.');
        setButtonDisabled(false);
        return;
      }

      if (isWhitelisted(email)) {
        console.log('Email domain is whitelisted. Proceeding with account creation.');
      } else {
        console.log('Email domain not whitelisted. Verifying email address.');
        const verificationResult = await verifyEmail(email);

        if (!verificationResult) {
          alert('Unable to verify email. Please try again.');
          setButtonDisabled(false);
          return;
        }

        if (verificationResult.is_disposable_email.value) {
          alert('Disposable email addresses are not allowed.');
          setButtonDisabled(false);
          return;
        }

        if (verificationResult.deliverability !== 'DELIVERABLE') {
          alert('This email address may not be deliverable. Please use a different email.');
          setButtonDisabled(false);
          return;
        }
      }

      if (!password || !re_password || !email || !first_name || !last_name) {
        alert("Please fill all the fields");
        setButtonDisabled(false);
        return;
      }

      if (password.length <= 8 || re_password.length <= 8) {
        alert("Password must be at least 8 characters long");
        setButtonDisabled(false);
        return;
      }


      if (password != re_password || !password || !re_password) {
        alert("Passwords do not match");
        setButtonDisabled(false);
        return;
      }
      // Proceed with account creation
      let data = JSON.stringify({
        email, first_name, last_name, password, re_password, ip_address: userIP
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BACKEND_URL + '/auth/users/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      const response = await axios.request(config);
      console.log(response);
      setView(2);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 429) {
          alert("Too many requests. Please try again later.");
        }
        if (error.response.data.email) {
          alert(error.response.data.email);
        }
        if (error.response.data.password) {
          alert(error.response.data.password);
        }
        if (error.response.data.message) {
          alert(error.response.data.message);
        }
        if (error.response.data.non_field_errors) {
          alert(error.response.data.non_field_errors);
        }
      }
    } finally {
      setButtonDisabled(false);
    }
  };

  const googleLogin = async () => {
    console.log("google")
    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"

        }
      }
      axios.defaults.withCredentials = true;
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/auth/o/google-oauth2/?redirect_uri=https://editai.in/google_auth/", config);
      console.log(response)
      window.location = response.data.authorization_url;
    }
    catch (e) {
      console.log(e);
    }

  }

  return (
    <>
      <DataBg />
      <Layout headerStyle={2} footerStyle={2}>
        <main className="main-content">
          <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
          <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
          <section className="login-area" style={{ padding: '5vw' }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-10">
                  {view === 1 ?
                    <div className="login-content">
                      <h3 className="title">Create your account</h3>
                      <span>👋 Welcome! Please enter your details.</span>
                      <form action="#" onSubmit={(e) => signup(e)}>
                        <div className="form-grp">
                          <label htmlFor="fname">First Name</label>
                          <input type="text" id="fname" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="form-grp">
                          <label htmlFor="lname">Last Name</label>
                          <input type="text" id="lname" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="form-grp">
                          <label htmlFor="email">Email</label>
                          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-grp">
                          <label htmlFor="word">Password</label>
                          <input type="password" id="word" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="form-grp">
                          <label htmlFor="word">Confirm Password</label>
                          <input type="password" id="cpass" value={re_password} onChange={(e) => setRePassword(e.target.value)} />
                        </div>

                        {!buttonDisabled ?
                          <button type="submit" className="sine-btn">sign in</button> :
                          <button type="submit" className="sine-btn flex justify-center gap-3 items-center text-white bg-purple-500 hover:bg-purple-600 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2.5 me-2 mb-2 dark:bg-purple-500 dark:hover:bg-purple-600 focus:outline-none dark:focus:ring-purple-600">
                            <Spinner color="purple" aria-label="Spinner button example" size="md" />
                            Signing in . . .
                          </button>}

                      </form>

                      <button className="google-btn" onClick={() => googleLogin()}><img src="assets/img/images/google.gif" alt="" height={50} width={50} /> sign in with google</button>

                    </div>
                    :
                    <div className="login-content">
                      <h3 className="title">Account Created!</h3>
                      <span>Thank you for registering. Please check your email for the activation link to activate your account.</span>
                      You will not be able to log in until your account is activated.
                    </div>
                  }
                </div>
                <div className="col-lg-7">
                  <div className="login-right-content-wrap">
                    <div className="login-right-bg" data-background="assets/img/bg/error_bg.jpg" />
                    <div className="login-right-content-inner">
                      <img src="assets/img/images/loginpageImg.png" alt="" />
                      <h4 className="title">Revolutionize Content Creation: Try <span>Edit AI</span> Today and watch your content soar</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}