import React, { useState } from 'react'
import Layout from 'components/layout/Layout';
import { FileInput, Label, Checkbox } from 'flowbite-react';
import styles from "./style.module.css";
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'axiosInstance';
import { Player } from '@lottiefiles/react-lottie-player';
import { validateAndCorrectFileName, validateDuration, validateFileSize, validateFileType } from 'utils';

export default function RemoveSilence() {

  const [video, setVideo] = useState();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(false)
  const videoGenerationLoadingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json"


  const removeSilence = async () => {

    if (!validateFileType(video)) {
      alert('Invalid file type. Please upload a video file.');
      return;
    }

    if (!validateFileSize(video)) {
      alert('File size exceeds the limit. Please upload a smaller file.');
      return;
    }

    const isDurationValid = await validateDuration(video);
    if (!isDurationValid) {
      alert('Video duration exceeds the limit. Please upload a shorter video.');
      return;
    }

    const validFileName = validateAndCorrectFileName(video);
    const correctedFile = new File([video], validFileName, { type: video.type });
    const formData = new FormData();
    setLoadingState(true)
    formData.append('input_video', video);
    formData.append('service_type', "back_ground_removal");

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/microservices/silencecutter/', formData, config);
    console.log(response.data)
    setLoadingState(false)
    if (response.data.success == 0) {
      return alert(response.data.message);
    }

    console.log(response.data);
    const id = response.data.id;


    navigate(`/result/microservices/${id}`);
    console.log(response);
  }

  return (
    <div>
      <Layout headerStyle={2} footerStyle={2}>
        <div className={loadingState ? styles.animation : styles.hidden}>
          <Player className={styles.loadingAnimation}
            src={videoGenerationLoadingLottie}
            loop
            autoplay
          />
          <h1 className={styles.LoadingText}>Your video is in the making! 📼</h1>
        </div>
        <div className={loadingState ? styles.hidden : styles.mainContainer}>
          <div className={styles.descriptionBox}>
            <div className={styles.descriptionContent}>
              <div className={styles.descriptionText}>
                <h2 className="title"><strong>Auto Silence <span>Removal</span></strong></h2>
                <p>Automatically detect and remove silent sections from your videos for a smoother, more engaging experience.</p>
                <p> Click on <strong>'Click to upload' </strong> below and upload your video. This AI tool will automatically detect and remove any silent segments for you.</p>
                <br></br>
                <p><strong>That's it! Enjoy a smoother video experience with automatic silence removal. 🎬✨</strong></p>
              </div>
              <video controls className={styles.demoVideo} >
                <source src="https://assets-editai.azureedge.net/videos/1716218951_Subtitle_Micro_Grgo2j_nitabg.mp4" type="video/mp4" loop autoPlay muted />
                Your browser does not support the video tag.
              </video>
            </div>

          </div>


          <br></br>
          <br></br>




          <h5 className="my-3">Upload video</h5>
          <div className="flex w-full items-center justify-center my-2">
            <Label
              htmlFor="dropzone-file"
              className="dark:hover:bg-bray-800 flex h-64  cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pb-6 pt-5 pr-20 pl-20">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLineJoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {video ? <span className="font-semibold">{video.name}</span> : <span className="font-semibold">Click to upload</span>}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">.mp4 format only</p>
              </div>
              <FileInput id="dropzone-file" className="hidden" onChange={(e) => { setVideo(e.target.files[0]) }} />
            </Label>
          </div>
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 my-5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => removeSilence()}>Submit</button>

        </div>
      </Layout>
    </div>
  )
}
