import React, { useState, useEffect } from 'react'
import Layout from "components/layout/Layout";
import { FileInput, Label } from 'flowbite-react';
import styles from "./style.module.css"
import axiosInstance from 'axiosInstance';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import SparklesText from "components/magicui/sparkles-text";
import { validateAndCorrectFileName, validateDuration, validateFileSize, validateFileType,uploadFile } from 'utils';

export default function CinematicSuite() {

  const [video, setVideo] = useState();
  const [trendySubtitle, setTrendySubtitle] = useState(false);
  const [frameType, setFrameType] = useState('square');
  const [fontFamily, setFontFamily] = useState('Poppins');
  const [highlightColor, setHighlightColor] = useState('Yellow');
  const [feVideo, setFeVideo] = useState();
  const [videoPreview, setVideoPreview] = useState('');
  const navigate = useNavigate();

  const [loadingState, setLoadingState] = useState(false)
  

  const uploadText="📤 Uploading Your Video... \n ✨ Setting the stage for cinematic brilliance.";
  const editingText="Hold Tight! 🎥 Bringing your cinematic masterpiece to life✨...";
  const uploadVideoLottie = "https://lottie.host/87977c37-dda0-4e93-8519-b3f5bdcbecf3/lXhE57RPHC.json";
  const videoEditingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json";

  const[lottieUrl,setLottieUrl]=useState(uploadVideoLottie);
  const[loadingText,setLoadingText]=useState(uploadText);

  // Arrays for options (replace with your actual video sources)
  const trendySubtitleList = [
    { type: 'blended', src: '/assets/demovideos/subtitles/BEAST.mp4' },
    { type: 'normal', src: '/assets/demovideos/subtitles/DAVID.mp4' }
  ];

  const frameTypeList = [
    { blend: false, frame: 'square', src: 'https://assets-editai.azureedge.net/videos-demos/editai-cinematic-suite-normal.mp4' },
    { blend: false, frame: 'long', src: 'https://assets-editai.azureedge.net/videos-demos/editai-cinematic-suite-normal-long.mp4' },
    { blend: true, frame: 'square', src: 'https://assets-editai.azureedge.net/videos-demos/editai-cinematic-suite-blended.mp4' },
    { blend: true, frame: 'long', src: 'https://assets-editai.azureedge.net/videos-demos/editai-cinematic-suite-blended-long.mp4' }
  ];

  const fontFamilies = ['Poppins', 'Montesserat'];
  const highlightColors = ['Yellow', 'Orange', 'Purple'];

  const createCinematicVideo = async () => {

    if (!validateFileType(feVideo)) {
      alert('Invalid file type. Please upload a video file.');
      return;
    }

    if (!validateFileSize(feVideo)) {
      alert('File size exceeds the limit. Please upload a smaller file.');
      return;
    }

    const isDurationValid = await validateDuration(feVideo);
    if (!isDurationValid) {
      alert('Video duration exceeds the limit. Please upload a shorter video.');
      return;
    }

    const validFileName = validateAndCorrectFileName(feVideo);
    const correctedFile = new File([feVideo], validFileName, { type: feVideo.type });
    setLoadingState(true);
    const link=await uploadFile(correctedFile,"video-container-cinematic");
    console.log(link);
    setLottieUrl(videoEditingLottie);
    setLoadingText(editingText);
    const edits={
      subtitle_style:"cinematic",
      "blend":trendySubtitle,
      "frame":frameType,
      "highlight_color":highlightColor, 
      "font_family":fontFamily, 
      "position":"bottom",
      "size":""
    }


    const config = {
      headers: { 'content-type': 'application/json' }
    }
    
    const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/microservices/cinematicsuite/',{link,edits} , config);
    setLoadingState(false);
    console.log(response.data)
    if (response.data.suceess == 0) {
      return alert(response.data.message);
    }

    console.log(response.data);
    const id = response.data.id;
    navigate(`/result/cinematicsuite/${id}`);
  }

  useEffect(() => {
    if (feVideo) {
      const videoURL = URL.createObjectURL(feVideo);
      setVideoPreview(videoURL);
      return () => URL.revokeObjectURL(videoURL);
    }
  }, [feVideo]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[loadingState])

  return (
    <div>
      <Layout headerStyle={2} footerStyle={2}>

        {/* Loading Animation */}
        <div className={loadingState ? styles.animation : styles.hidden}>
          <Player className={styles.loadingAnimation}
            src={lottieUrl}
            loop
            autoplay
          />
          <h1 className={styles.LoadingText}>{loadingText}</h1>
        </div>

        <div className={loadingState ? styles.hidden : styles.mainContainer}>

          {/* Page Heading */}
          <div className='border-b-2 mb-4 border-gray-200 dark:border-gray-700'>
            <SparklesText
              text="Cinematic Suite 🎬"
              className={`font-display text-center text-6xl font-extrabold tracking-[-0.02em] drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem] p-4 ${styles.pageHeading}`}
            />
          </div>

          {/* Description Box */}
          {/* <div className={styles.descriptionBox}>
          <div className={styles.descriptionContent}>
            <div className={styles.descriptionText}>
              <h2><strong>Cinematic Suite 🎬</strong></h2>
              <p>Elevate your videos with our <strong>Cinematic Suite</strong>. Add trendy subtitles, select cinematic frames, and choose unique font families to make your content stand out.</p>
            </div>
            <video controls className={styles.demoVideo}>
              <source src="/assets/demovideos/cinematic/demo.mp4" type="video/mp4" loop />
              Your browser does not support the video tag.
            </video>
          </div>       
        </div> */}

          {/* <br></br><br></br> */}

          {/* How-To Description */}
          {/* <div className={styles.descriptionBox}>
          <div className={styles.descriptionContent}>
            <div className={styles.descriptionText}>
              <h2><strong>How to create a Cinematic Video? 🤔</strong></h2>
              <strong>1. Upload your video</strong>
              <p>Click on <strong>'Click to upload'</strong> and upload your video.</p>
              <br></br>
              <strong>2. Choose Trendy Subtitle</strong>
              <p>Select from <strong>blended</strong> or <strong>normal</strong> subtitles that match your content’s tone.</p>
              <br></br>
              <strong>3. Choose Frame Type</strong>
              <p>Pick a <strong>square</strong> or <strong>long</strong> frame to fit your desired platform’s format.</p>
              <br></br>
              <strong>4. Select Font Family & Highlight Colors</strong>
              <p>Personalize your subtitles further by selecting a <strong>font family</strong> and optional <strong>highlight colors</strong> (yellow, orange, purple) to emphasize certain words.</p>
              <br></br>
              <strong>5. Submit</strong>
              <p>Once done, let the Cinematic Suite work its magic!</p>
            </div>
            <video controls className={styles.demoVideo} >
              <source src="https://assets-editai.azureedge.net/videos/1716218951_Subtitle_Micro_Grgo2j_nitabg.mp4" type="video/mp4" loop autoPlay muted />
              Your browser does not support the video tag.
            </video>
          </div>       
        </div> */}

          {/* Choose Trendy Subtitle */}
          {/* <div className={styles.subtitleContainer}>
          <p>Choose Trendy Subtitle Type</p>
          <div className={styles.subtitles}>
            {trendySubtitleList.map((option) => (
              <video
                key={option.type}
                onClick={() => setTrendySubtitle(option.type)}
                src={option.src}
                className={trendySubtitle === option.type ? styles.selected : 'rounded-lg'}
                loop muted autoPlay
              ></video>
            ))}
          </div>
        </div> */}

          {/* Choose Frame Type */}
          <div className={styles.subtitleContainer}>
            <p className='mb-3 text-xl font-bold text-center'>Choose Frame Type</p>
            <div className={styles.subtitles}>
              {frameTypeList.map((option) => (
                <video
                  key={option.type}
                  onClick={() => {setTrendySubtitle(option.blend);
                    setFrameType(option.frame);
                  }}
                  src={option.src}
                  className={trendySubtitle === option.blend && frameType === option.frame ? styles.selected : 'rounded-lg'}
                  loop muted autoPlay
                ></video>
              ))}
            </div>
          </div>

          {/* Choose Font Family */}
          <div className="buttons py-1 mx-2">
            <p className='mb-2 text-lg text-center'>Font Family</p>
            <div className='flex flex-col md:flex-row gap-0 md:gap-1'>
              {fontFamilies.map((font) => (
                <button
                  key={font}
                  className={`inline-block rounded border px-12 py-3 text-sm font-medium md:my-1 focus:outline-none focus:ring ${fontFamily === font
                    ? 'text-white bg-indigo-600'
                    : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                    }`}
                  onClick={() => setFontFamily(font)}
                >
                  {font}
                </button>
              ))}
            </div>
          </div>

          {/* Upload Video */}
          <p className='my-3 text-lg text-center'>Upload video</p>
          <div className="flex w-full items-center justify-center pt-2">
            <Label
              id="choosefile"
              htmlFor="dropzone-file"
              className="dark:hover:bg-bray-800 flex h-52  cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center px-12">

                {feVideo ? <video className={styles.uploadedVideo} src={videoPreview}></video> :
                  <svg
                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLineJoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>}
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {feVideo ?
                    <>
                      <span className="font-semibold">{feVideo.name}</span>
                    </>
                    : <span className="font-semibold">Click to upload</span>}
                </p>
                {feVideo ? <></> : <p className="text-xs text-gray-500 dark:text-gray-400">.mp4 format only</p>}
              </div>
              <FileInput id="dropzone-file" className="hidden" onChange={(e) => { setFeVideo(e.target.files[0]) }} />
            </Label>
          </div>

          {/* Highlighting Word Color (Optional) */}
          <div className="buttons py-1 mx-2">
            <p className='mt-3 mb-2 text-lg text-center'>Highlight Word Color (Optional)</p>
            <div className='flex flex-col md:flex-row gap-0 md:gap-1'>
              {/* <button
                className={`inline-block rounded border px-12 py-3 text-sm font-medium md:my-1 focus:outline-none focus:ring ${highlightColor === ''
                  ? 'text-white bg-indigo-600'
                  : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                  }`}
                onClick={() => setHighlightColor('')}
              >
                No Highlight
              </button> */}
              {highlightColors.map((color) => (
                <button
                  key={color}
                  className={`inline-block rounded border px-12 py-3 text-sm font-medium my-1 focus:outline-none focus:ring ${highlightColor === color
                    ? 'text-white bg-indigo-600'
                    : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                    }`}
                  onClick={() => setHighlightColor(color)}
                >
                  {color}
                </button>
              ))}
            </div>
          </div>

          <button
            type="button"
            className="my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 
          font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none 
          dark:focus:ring-blue-800"
            onClick={() => createCinematicVideo()}
          >
            Submit
          </button>

        </div>
      </Layout>
    </div>
  )
}
