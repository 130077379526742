const { BlobServiceClient } = require('@azure/storage-blob');


function validateDuration(file, maxDurationInSeconds = 600) {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
  
      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(videoElement.src);
        resolve(videoElement.duration <= maxDurationInSeconds);
      };
    });
  }

  function validateFileSize(file, maxSizeInMB = 250) {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    return file.size <= maxSizeInBytes;
  }

  function validateFileType(file) {
    const allowedTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/mkv'];
    return allowedTypes.includes(file.type);
  }

  function validateAndCorrectFileName(file, maxLength = 50) {
    let fileName = file.name;
  
  // Replace spaces with underscores
  let sanitizedFileName = fileName.replace(/\s+/g, '_');

  // Sanitize the file name by replacing any other unsafe characters
  sanitizedFileName = sanitizedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
  
    // Ensure the sanitized name is not too long
    if (sanitizedFileName.length > maxLength) {
      // Trim the base name to fit within the allowed length (accounting for file extension)
      const extension = sanitizedFileName.split('.').pop();
      const baseName = sanitizedFileName.slice(0, maxLength - extension.length - 1);
      sanitizedFileName = `${baseName}.${extension}`;
    }
  
    // If the sanitized name is different from the original, return the new name
    if (sanitizedFileName !== fileName) {
      return sanitizedFileName;
    }
  
    // If no changes were needed, return the original name
    return fileName;
  }


  const uploadFile = async (file,containerName=null) => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    if(!containerName){
    if (file.type.startsWith('image/')) {
      containerName = 'image-container';
    } else if (file.type.startsWith('video/')) {
      containerName = 'custom-video-container';
    } else {
      alert('File type not supported. Please upload an image or video.');
      return;
    }}

    
    console.log(containerName);

    const sasToken = process.env.REACT_APP_AZURE_BLOB_SAS_TOKEN;
    const storageAccountName = process.env.REACT_APP_AZURE_BLOB_ACCOUNT;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Create a unique name for the blob
    const blobName = new Date().getTime() + '-' + file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    console.log(blockBlobClient);

    try {
      await blockBlobClient.uploadBrowserData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });
      const url = blockBlobClient.url.split('?')[0]; // Get the URL of the uploaded file

      // alert('File uploaded successfully!');
      return url; // Return the file URL
    } catch (error) {
      console.error('Upload failed:', error.message);
      alert('Upload failed!');
      return null;
    }
  };


  function timeAgo(inputTime) {
    const inputDate = new Date(inputTime);
    const now = new Date();
    const diffInSeconds = Math.floor((now - inputDate) / 1000);

    if (diffInSeconds < 60) {
        return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} min ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hr${hours > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 172800) {
        return "yesterday";
    } else {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? "s" : ""} ago`;
    }
}

// Example usage:


exports.validateDuration = validateDuration;
exports.validateFileSize = validateFileSize;
exports.validateFileType = validateFileType;
exports.uploadFile=uploadFile;
exports.validateAndCorrectFileName = validateAndCorrectFileName; 
exports.timeAgo = timeAgo;