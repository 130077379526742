import Layout from "components/layout/Layout";
import DataBg from "components/layout/DataBg"
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export default function Login() {

    const [password, setPassword] = useState();
    const [re_password, setRePassword] = useState();

    const params = useParams();

    const navigate = useNavigate();
    const handlesubmit = async () => {
        try {
            if (!password || !re_password) {
                return alert("fill all the fields")
            }
            const uid = params.uid;
            const token = params.token;
            const data = {
                uid, token, new_password: password, re_new_password: re_password
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/users/reset_password_confirm/', JSON.stringify(data), { headers: { "content-type": "application/json" } });
            console.log(response);

            alert('Password changed successfully');
            // Cookies.set('refreshToken', response.data.refresh, { expires: 7 });
            navigate('/login');
        }
        catch (err) {

            console.log(err);
            alert("something went wrong");

        }
    }

    const googleLogin = async () => {
        console.log("google")
        try {
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"

                }
            }
            axios.defaults.withCredentials = true;
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/auth/o/google-oauth2/?redirect_uri=https://editai.in/google_auth/", config);
            console.log(response)
            window.location = response.data.authorization_url;
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <DataBg />
            <Layout headerStyle={2} footerStyle={2}>
                <main className="main-content">
                    <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
                    <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
                    <section className="login-area">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-10 pb-12">
                                    <div className="login-content ms-6">

                                        <span>👋 Welcome back! Please enter your details.</span>
                                        <form onSubmit={(e) => { e.preventDefault(); handlesubmit() }}>

                                            <div className="form-grp">
                                                <label htmlFor="word">New Password</label>
                                                <input type="password" id="word" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <div className="form-grp">
                                                <label htmlFor="word">Confirm NewPassword</label>
                                                <input type="password" id="word" value={re_password} onChange={(e) => setRePassword(e.target.value)} />
                                            </div>

                                            <button type="submit" className="sine-btn">Submit</button>


                                        </form>


                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="login-right-content-wrap">
                                        <div className="login-right-bg" data-background="/assets/img/bg/error_bg.jpg" />
                                        <div className="login-right-content-inner">
                                            <img src="/assets/img/images/loginpageImg.png" alt="" />
                                            <h4 className="title">Revolutionize Your Content: Try <span>Edit AI Today</span> and watch your content soar</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}